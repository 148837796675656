import {
  EVERPRO_DOMAIN,
  JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, UNKNOWN_DOMAIN, getAppDomain,
  getJoistVersion,
} from './domains';
import {
  ServiceFusionSolutionOrgName, JoistSolutionOrgName, JoistCode,
  InvoiceSimpleSolutionOrgName,
  RoofsnapSolutionOrgName,
  KickservSolutionOrgName,
} from '../common/constants';
import { useFeatureFlags } from './FeatureFlagsProvider';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { useAppSelector } from '../store/hooks';

interface SolutionOrgs {
  [key: string]: {
    [key: string]: unknown;
    isIframe?: boolean;
  }
}

// manage solution org related resources based on the current domain
export function useSolutionOrg() {
  const { homeDepotOnlySN, homeDepotOnlyEverPro } = useFeatureFlags();
  const { solutionOrgId } = useAppSelector(selectMemberSession); // obtained after login
  const appDomain = getAppDomain();

  const getEverproResources = () => {
    if (appDomain !== EVERPRO_DOMAIN) {
      return {};
    }
    const everProResources: {
      [key: string]: unknown;
      isIframe?: boolean;
    } = {
      envSolutionOrgTitle: process.env.REACT_APP_EVER_PRO_SITE_TITLE,
      termsOfServiceUrl: 'https://servicenation.com/edge-tos/',
      numberOfOnboardingForms: 4, // homeDepotOnlyEverPro ? 2 : 3,
      // Everpro Edge will also use the Joist code when enrolling in Home Depot
      programCode: JoistCode,
      solutionOrgSpecificAppName: 'EverPro Edge',
      // TODO: update these flags
      solutionOrgHasOnlyHomeDepot: homeDepotOnlyEverPro,
      hasDateBasedPartnerListCard: homeDepotOnlyEverPro,
      isIframe: window !== window.parent,
      enrollOnSplashScreen: false,
      paypalConnectionReturnUrl: process.env.REACT_APP_EVERPRO_PAYPAL_CONNECTION_RETURN_URL,
      domainName: appDomain,
      productQuestion: true,
    };

    // add everpro solution org specific resources
    switch (solutionOrgId) {
      case process.env.REACT_APP_KICKSERV_SOLUTION_ID:
        everProResources.solutionOrgName = KickservSolutionOrgName;
        everProResources.envSolutionOrgFormId = process.env.REACT_APP_KICKSERV_FORM_ID;
        everProResources.envRewardProgramId =
          process.env.REACT_APP_KICKSERV_REWARD_PROGRAM_ID;
        everProResources.supportEmail = 'hello@kickserv.com'; // TODO: update with kickserv email
        everProResources.envSolutionOrgId = process.env.REACT_APP_KICKSERV_SOLUTION_ID;
        break;
      case process.env.REACT_APP_ROOFSNAP_SOLUTION_ID:
        everProResources.solutionOrgName = RoofsnapSolutionOrgName;
        everProResources.envSolutionOrgFormId = process.env.REACT_APP_ROOFSNAP_FORM_ID;
        everProResources.envRewardProgramId =
          process.env.REACT_APP_ROOFSNAP_REWARD_PROGRAM_ID;
        everProResources.supportEmail = 'hello@roofsnap.com'; // TODO: update with roofsnap email
        everProResources.envSolutionOrgId = process.env.REACT_APP_ROOFSNAP_SOLUTION_ID;
        break;
      default: // invoice simple is the default case
        everProResources.solutionOrgName = InvoiceSimpleSolutionOrgName;
        everProResources.envSolutionOrgFormId = process.env.REACT_APP_INVOICE_SIMPLE_FORM_ID;
        everProResources.envRewardProgramId =
          process.env.REACT_APP_INVOICE_SIMPLE_REWARD_PROGRAM_ID;
        everProResources.supportEmail = 'support@invoicesimple.com';
        everProResources.envSolutionOrgId = process.env.REACT_APP_INVOICE_SIMPLE_SOLUTION_ID;
        break;
    }

    return everProResources;
  };

  const solutionOrgs: SolutionOrgs = {
    [JOIST_DOMAIN]: {
      onboardingVersion: getJoistVersion(),
      solutionOrgName: JoistSolutionOrgName,
      envSolutionOrgFormId: process.env.REACT_APP_JOIST_EDGE_FORM_ID,
      numberOfOnboardingForms: 4, // 2,
      envSolutionOrgTitle: process.env.REACT_APP_JOIST_SITE_TITLE,
      envRewardProgramId: process.env.REACT_APP_REWARD_PROGRAM_ID,
      envSolutionOrgId: process.env.REACT_APP_SOLUTION_ID,
      IsInAppExperience: true,
      programCode: JoistCode,
      supportEmail: 'hello@joist.com',
      isIframe: window !== window.parent,
      solutionOrgHasOnlyHomeDepot: true,
      useHomeDepotOnlyBannerText: true,
      solutionOrgSpecificAppName: 'Joist Edge',
      enrollOnSplashScreen: false,
      paypalConnectionReturnUrl: process.env.REACT_APP_JOIST_PAYPAL_CONNECTION_RETURN_URL,
      domainName: appDomain,
      productQuestion: false,
    },
    [SERVICE_FUSION_DOMAIN]: {
      solutionOrgName: ServiceFusionSolutionOrgName,
      envSolutionOrgTitle: process.env.REACT_APP_SITE_TITLE,
      termsOfServiceUrl: 'https://servicenation.com/edge-tos/',
      envSolutionOrgFormId: process.env.REACT_APP_SF_FORM_ID,
      numberOfOnboardingForms: 4, // homeDepotOnlySN ? 2 : 3,
      envRewardProgramId: process.env.REACT_APP_SF_REWARD_PROGRAM_ID,
      envEnableMarketoDataSubmission: process.env.REACT_APP_ENABLE_MARKETO_DATA_SUBMISSION === 'true',
      envSolutionOrgId: process.env.REACT_APP_SF_SOLUTION_ID,
      // SN Edge will also use the Joist code when enrolling in Home Depot
      programCode: JoistCode,
      supportEmail: 'support@servicefusion.com',
      solutionOrgHasOnlyHomeDepot: homeDepotOnlySN,
      hasDateBasedPartnerListCard: homeDepotOnlySN,
      solutionOrgSpecificAppName: 'Service Fusion Edge',
      enrollOnSplashScreen: false,
      paypalConnectionReturnUrl: process.env.REACT_APP_SERVICE_FUSION_PAYPAL_CONNECTION_RETURN_URL,
      domainName: appDomain,
      productQuestion: false,
    },
    [EVERPRO_DOMAIN]: getEverproResources(),
    [UNKNOWN_DOMAIN]: {
      main: null,
    }, // for backward compatibility with web.dev.optimusapp.io and web.staging.optimusapp.io
  };

  return solutionOrgs[appDomain];
}
