import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Snackbar,
  Typography,
  Divider,
  Theme,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectFollowTopics } from '../../store/slices/contentSlice';
import { addFollowTopic, deleteFollowTopic } from '../../store/thunks/contentThunk';
import { TestIds } from '../../assets/testIds';

interface FollowTopicProps {
  topicIndx: number
  homeCardFollowTopic?: boolean
}

interface FollowTopicsCardProps {
  isHomeCard: boolean
}

const dividerStyle = (theme: Theme) => ({
  height: '1px',
  color: theme.palette.color2.main,
  backgroundColor: theme.palette.color2.main,
} as const);

const followButtonStyle = (theme: Theme) => ({
  textTransform: 'none',
  color: theme.palette.secondary.main,
  borderRadius: 20,
  borderColor: theme.palette.secondary.main,
} as const);

export function FollowTopicsCard({
  isHomeCard,
}: FollowTopicsCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const memberFollowedTopics = useAppSelector(selectFollowTopics);
  const [followTopics, setFollowTopics] = useState([
    {
      name: 'Hiring',
      description: 'Hire The Best People',
      followed: false,
      dataTestId: TestIds.followTopics.hiringBtn,
    },
    {
      name: 'Pricing',
      description: 'Learn How The Best Do It',
      followed: false,
      dataTestId: TestIds.followTopics.pricingBtn,
    },
    {
      name: 'Lead Generation',
      description: 'Learn How To Get Quality Leads',
      followed: false,
      dataTestId: TestIds.followTopics.leadGenerationBtn,
    },
    {
      name: 'Technology',
      description: 'New Technologies',
      followed: false,
      dataTestId: TestIds.followTopics.technologyBtn,
    },
    {
      name: 'Marketing',
      description: 'Up Your Game',
      followed: false,
      dataTestId: TestIds.followTopics.marketingBtn,
    },
  ]);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [loadingTopicIndex, setLoadingTopicIndex] = useState<number | null>(null);

  useEffect(() => {
    const newFollowTopics = followTopics.map((item) => {
      const followedTopic =
        memberFollowedTopics.find((topic) => topic.tagName === item.name);
      return followedTopic ? { ...item, followed: true } : { ...item, followed: false };
    });
    setFollowTopics(newFollowTopics);
    setLoadingTopicIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFollowedTopics]);

  const handleSnackBarClose = () => {
    setOpenErrorSnackbar(false);
  };

  const handleFollowTopic = async (name: string, followed: boolean, topicIndx: number) => {
    setLoadingTopicIndex(topicIndx);
    try {
      if (followed) {
        await dispatch(addFollowTopic({ relationType: 'follow', tagName: name })).unwrap();
      } else {
        const topicToBeDeleted = memberFollowedTopics.find((topic) => topic.tagName === name);
        if (topicToBeDeleted) {
          await dispatch(deleteFollowTopic(topicToBeDeleted.id)).unwrap();
        }
      }
    } catch (e) {
      console.log(e);
      setLoadingTopicIndex(null);
      setOpenErrorSnackbar(true);
    }
  };

  const onFollowBtnClick = (index: number) => {
    handleFollowTopic(followTopics[index].name, !followTopics[index].followed, index);
  };

  const FollowTopic = ({ topicIndx, homeCardFollowTopic }: FollowTopicProps) => {
    const isLoading = loadingTopicIndex === topicIndx;
    const followText = followTopics[topicIndx].followed ? `${t('button.following')}` : `${t('button.follow')}`;

    return (
      <>
        {homeCardFollowTopic ? (
              <Button
                data-testid={followTopics[topicIndx].dataTestId}
                variant="outlined"
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19.09px',
                  letterSpacing: '0.5px',
                  textAlign: 'center',
                  textTransform: 'none',
                  borderRadius: 100,
                  padding: '7.5px 20px 7.5px 10px',
                  marginBottom: theme.spacing(1.5),
                  margin: '0 10px 20px',
                  border: `2px solid ${theme.palette.secondary.dark}`,
                  color: followTopics[topicIndx].followed ?
                    theme.palette.common.white :
                    theme.palette.secondary.dark,
                  backgroundColor: followTopics[topicIndx].followed ?
                    theme.palette.secondary.dark :
                    theme.palette.common.white,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '12px',
                    padding: '5px 15px 5px 7.5px',
                  },
                  '&:hover': {
                    backgroundColor: followTopics[topicIndx].followed ?
                      theme.palette.secondary.dark :
                      theme.palette.secondary.light,
                    color: followTopics[topicIndx].followed ?
                      theme.palette.common.white :
                      theme.palette.secondary.dark,
                    border: `2px solid ${theme.palette.secondary.dark}`,
                  },
                  '&:disabled': {
                    backgroundColor: followTopics[topicIndx].followed ?
                      theme.palette.secondary.dark :
                      theme.palette.secondary.light,
                    color: followTopics[topicIndx].followed ?
                      theme.palette.common.white :
                      theme.palette.secondary.dark,
                    border: `2px solid ${theme.palette.secondary.dark}`,
                  },
                }}
                onClick={() => onFollowBtnClick(topicIndx)}
                disabled={isLoading}
              >
                {followTopics[topicIndx].followed ? <CheckIcon /> : <AddIcon />}
                {followTopics[topicIndx].name}
              </Button>
        ) : (
          <Box
            sx={{
              marginLeft: theme.spacing(3.375),
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
              [theme.breakpoints.up('md')]: {
                marginRight: theme.spacing(3),
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: theme.spacing(3.5),
            }}>
              <Box>
                <Typography variant='h4'>{followTopics[topicIndx].name}</Typography>
                <Typography variant='body1' sx={{
                  color: theme.palette.color2.main,
                  marginBottom: theme.spacing(2),
                }}>
                  {followTopics[topicIndx].description}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={[
                  followButtonStyle,
                  {
                    ...(followTopics[topicIndx].followed ? {
                      background: theme.palette.secondary.main,
                      color: theme.palette.common.white,
                    } : {}),
                  },
                  {
                    '&:hover': {
                      backgroundColor: followTopics[topicIndx].followed ?
                        theme.palette.secondary.main : theme.palette.common.white,
                      color: followTopics[topicIndx].followed ?
                        theme.palette.common.white : theme.palette.secondary.main,
                      borderColor: theme.palette.secondary.main,
                    },
                  },
                ]}
                onClick={() => onFollowBtnClick(topicIndx)}
                disabled={isLoading}
              >
                {followText}
              </Button>
            </Box>
            {topicIndx < followTopics.length - 1 && (
              <Divider sx={[{
                margin: `${theme.spacing(1)}px 0`,
              }, dividerStyle]} />
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <Card sx={{
      minWidth: 275,
      width: '100%',
      borderRadius: theme.spacing(1.25),
      boxShadow: 0,
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
      ...(isHomeCard && {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.dark,
        pt: `${theme.spacing(0)} !important`,
        pr: `${theme.spacing(0)} !important`,
      }),
    }}>
      <Snackbar
        sx={{
          [theme.breakpoints.up('md')]: {
            width: '80%',
            maxWidth: theme.spacing(126.5),
          },
        }}
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }} onClose={handleSnackBarClose}>
          <AlertTitle>{t('error.generalMessage')}</AlertTitle>
        </Alert>
      </Snackbar>
      <CardContent sx={{
        borderRadius: theme.spacing(1.25),
        border: isHomeCard ? '0px' : `1px solid ${theme.palette.grey3.main}`,
        position: 'relative',
        ...(isHomeCard && {
          pt: theme.spacing(0),
          '&:last-child': {
            pb: `${theme.spacing(0)} !important`,
          },
        }),
        paddingLeft: isHomeCard ? theme.spacing(0) : theme.spacing(0.25),
        paddingRight: isHomeCard ? theme.spacing(0) : theme.spacing(1.5),
        paddingBottom: isHomeCard ? theme.spacing(0) : `${theme.spacing(1.5)} !important`,
        letterSpacing: '0.52px',
        [theme.breakpoints.down('sm')]: {
          paddingBottom: isHomeCard ? theme.spacing(0) : `${theme.spacing(1.25)} !important`,
        },
      }}>
        {isHomeCard ? (
              <>
                {followTopics.map((topic, index) => (
                    <FollowTopic key={index} topicIndx={index} homeCardFollowTopic={isHomeCard} />
                ))}
              </>
        ) : (
          <>
            {followTopics.map((topic, index) => (
              <FollowTopic key={index} topicIndx={index} />
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
}
