import {
  Box, Checkbox, CircularProgress, Divider, Grid, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReactComponent as HdLogo } from '../assets/images/hd_logo.svg';
import { ReactComponent as HdSupplyLogo } from '../assets/images/hdSupply_logo.svg';
import CallToActionButton from './CallToActionButton';
import { getCompanyPartnerRelations } from '../store/thunks/dashboardThunk';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectMemberInformation, selectMemberSession, setOptedToSkipHdEnrollment } from '../store/slices/onboardingSlice';
import { ShoppingStatus } from './constants';
import { updateCompanyPartnerRelations } from '../api/dashboardAPI';
import { useOnboardEventTrackingWithHeap } from './useOnboardEventTrackingWithHeap';
import { TestIds } from '../assets/testIds';

interface ContinueBtnProps {
  onBtnClick?: () => void;
  btnText?: JSX.Element | string;
  disabled?:boolean;
  dataTestId?:string;
}

interface WelcomeModalPartnerSelectionProps{
  onContinue: () => void;
  setOpenErrorSnackbar: (val:boolean) => void;
}

interface PartnerItem {
  id: number;
  partnerId: string;
  name: string;
  logo: () => JSX.Element;
  dataTestId:string;
}

interface PartnerSelectorProps {
  partner: PartnerItem;
  dataTestId?:string;
}

export function ContinueBtn({
  onBtnClick, btnText, disabled, dataTestId,
}:ContinueBtnProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
      <CallToActionButton
                text={btnText || t('button.continue') as string}
                type={'button'}
                onBtnClick={onBtnClick}
                sx={{
                  position: 'static',
                  width: '100%',
                  marginTop: theme.spacing(0),
                  marginBottom: theme.spacing(0),
                  paddingTop: theme.spacing(0.5),
                  paddingBottom: theme.spacing(0.5),
                  fontWeight: '600',
                  fontSize: theme.spacing(2),
                  background: theme.palette.color16.main,
                  borderRadius: theme.spacing(1.25),
                  border: `1px solid ${theme.palette.color16.main}`,
                  '&:hover': {
                    backgroundColor: theme.palette.color16.main,
                  },
                  '&:disabled': {
                    backgroundColor: theme.palette.color16.main,
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    marginLeft: theme.spacing(0),
                  },
                }}
                optionalStackStyles={{
                  display: 'inline-block',
                  width: '55%',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    display: 'block',
                  },
                }}
                disable={disabled}
                data-testid={dataTestId}
              />
  );
}

export function WelcomeModalPartnerSelection({
  onContinue,
  setOpenErrorSnackbar,
}:WelcomeModalPartnerSelectionProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const trackOnboardEventsWithHeap = useOnboardEventTrackingWithHeap();
  const { memberId } = useAppSelector(selectMemberSession);
  const { company } = useAppSelector(selectMemberInformation);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);

  const partnerList: PartnerItem[] = [
    {
      id: 1,
      partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID as string,
      name: 'The Home Depot',
      logo: () => <HdLogo width='68px' height='69px'/>,
      dataTestId: TestIds.welcomeModal.homeDepotPartnerSelector,
    },
    {
      id: 2,
      partnerId: process.env.REACT_APP_HD_SUPPLY_PARTNER_ID as string,
      name: 'HD Supply',
      logo: () => <HdSupplyLogo width='72px' height='49px'/>,
      dataTestId: TestIds.welcomeModal.hdSupplyPartnerSelector,
    },
  ];

  useEffect(() => {
    if (company?.id) {
      dispatch(getCompanyPartnerRelations(company?.id))
        .then(unwrapResult)
        .then((companyPartnerRelations) => {
          companyPartnerRelations?.forEach((relation) => {
            if (relation.shoppingStatus === ShoppingStatus.Shopping) {
              const partner = partnerList.find(
                (item) => item.partnerId && item.partnerId === relation.partnerId,
              );
              if (partner) {
                setSelected((prevSelected) => (
                  !prevSelected.includes(partner.id) ?
                    [...prevSelected, partner.id] :
                    prevSelected
                ));
              }
            }
          });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  const handleToggle = (id: number) => {
    setSelected((prevSelected) => (prevSelected.includes(id) ?
      prevSelected.filter((selectedId) => selectedId !== id) :
      [...prevSelected, id]));
  };

  const onBtnClick = () => {
    setLoading(true);
    const updateRelationsPromises = partnerList.map((partner) => updateCompanyPartnerRelations({
      partnerId: partner.partnerId,
      companyId: company?.id as string,
      shoppingStatus: selected.includes(partner.id) ? ShoppingStatus.Shopping :
        ShoppingStatus.NotShopping,
    }));

    // Wait for all promises to resolve
    Promise.all(updateRelationsPromises)
      .then(() => {
        setOpenErrorSnackbar(false);
        const hdPartner = partnerList.find(
          (item) => item.partnerId &&
          item.partnerId === process.env.REACT_APP_HOME_DEPOT_PARTNER_ID as string,
        );
        if (selected.includes(hdPartner?.id as number)) {
          // persist locally to defer completing hd enrollment
          dispatch(setOptedToSkipHdEnrollment(true));
        }
        trackOnboardEventsWithHeap('clicked-continue-on-welcome-modal-partner-select-page', {
          memberId,
        });
        onContinue();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setOpenErrorSnackbar(true);
      });
  };

  const PartnerSelector = ({ partner, dataTestId }:PartnerSelectorProps) => (
      <Box display='flex' flexDirection='column'
      data-testid={dataTestId}
      sx={{
        height: '100%',
        cursor: 'pointer',
        pt: theme.spacing(1.5),
        borderRadius: theme.spacing(1),
        border: `1px solid ${selected.includes(partner.id) ? theme.palette.secondary.dark :
          theme.palette.grey2.main}`,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
      }}
      onClick={() => handleToggle(partner.id)}
      >
        <Box display={'flex'}>
          <Checkbox
            checked={selected.includes(partner.id)}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.secondary.light,
              },
              '&.MuiCheckbox-root': {
                p: theme.spacing(0),
                pl: theme.spacing(1.25),
              },
              '& .MuiSvgIcon-root': {
                color: theme.palette.grey2.main,
              },
              '&.Mui-checked .MuiSvgIcon-root': {
                color: theme.palette.secondary.dark,
              },
            }}
          />
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignItems='center'
        sx={{
          mb: partner.name === 'HD Supply' ? theme.spacing(5) : theme.spacing(2.5),
          mt: theme.spacing(2.5),
        }}>
          <partner.logo/>
        </Box>
        <Divider
        sx={{
          width: '100%',
          borderBottomWidth: 1, // Thickness of the line
          borderColor: theme.palette.grey2.main, // Color of the line
        }}
        />
        <Box textAlign={'center'} sx={{
          p: `${theme.spacing(2.5)} ${theme.spacing(3.125)}`,
        }}>
          <Typography variant='body2'>{partner.name}</Typography>
        </Box>
      </Box>
  );

  return (
    <Box>
      <Typography variant='h8' mb={theme.spacing(1.5)}
      sx={{
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(3),
          fontWeight: 600,
        },
      }}
      >
        {t('welcomeModal.partnerSlide.heading1')}
      </Typography>
      <Typography mb={theme.spacing(7.5)} mt={theme.spacing(2)}>
        {t('welcomeModal.partnerSlide.heading2')}
      </Typography>
      <Grid container spacing={2} sx={{
        mb: theme.spacing(7.5),
        width: '60%',
        ml: 'auto',
        mr: 'auto',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          ml: theme.spacing(0),
          mr: theme.spacing(0),
        },
      }}>
      {partnerList.map((partner, indx) => (
        <Grid item key={indx} xs={6} md={6}>
        <PartnerSelector partner={partner} dataTestId={partner.dataTestId}/>
        </Grid>
      ))}
      </Grid>
      <ContinueBtn
      onBtnClick={onBtnClick}
      btnText={loading ? <CircularProgress color="inherit" size={24} /> : undefined}
      disabled={loading}
      dataTestId={TestIds.welcomeModal.partnerSelectPageBtn}
      />
    </Box>
  );
}
