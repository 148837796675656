import { styled } from '@mui/system';
import {
  MenuItem, Select, TextField, Theme, useTheme,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { QuestionOption } from './models/Question';
import DraggableListComponent from './dragAndDrop/DraggableListComponent';
import ImageOptionComponent from './ImageOptionComponent';
import PollQuestionComponent from './PollQuestionComponent';
import GroupDropdownComponent from './GroupDropdownComponent';
import { GroupAutocompleteComponent } from './QuestionComponentParts/GroupAutocompleteComponent';
import { BusinessTrade } from './models/Member';
import { QuestionComponentAutocomplete } from './QuestionComponentParts/QuestionComponentAutocomplete';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
})) as typeof TextField;

export const useStylesForQuestionComponent = makeStyles((theme: Theme) => ({
  root: {
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: theme.palette.common.black,
    },
  },
  inputRoot: {
    color: `${theme.palette.common.black} !important`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '13px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&.Mui-focused': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&.MuiOutlinedInput-input': {
      borderRadius: '13px !important',
    },
  },
}));

type InputProps = {
  inputValue: unknown;
  type: string;
  placeholderText: string;
  subTitle?: string;
  options?: QuestionOption[];
  selectedOptions?: BusinessTrade[];
  meta?: {
    [k: string]: unknown;
  };
  onChange: (...event: unknown[]) => void;
  onBlur?: () => void,
  groupDropDownDefaultValues?: unknown;
  groupAutoComplete?: boolean;
  textFieldDisabled?: boolean;
  borderStyle?: boolean;
  isOutlined?: boolean;
  calledFromOnboarding?: boolean;
  ['data-testid']?: string;
};

const QuestionComponent = (
  {
    type, placeholderText, meta, groupDropDownDefaultValues, groupAutoComplete,
    inputValue, onChange, onBlur, subTitle, textFieldDisabled, options = [], selectedOptions = [],
    borderStyle, isOutlined, calledFromOnboarding, 'data-testid': dataTestId,
  }: InputProps,
) => {
  const { t } = useTranslation();
  const classes = useStylesForQuestionComponent();
  const theme = useTheme();

  const getTranslation = (input: string) => {
    const key = `onboarding.questionPlaceholder.${input}`;
    return `${t(key)}`;
  };

  const getPhoneNumberValue = (input: string) => {
    if (input && !(input.startsWith('+') || input.startsWith('1'))) {
      return `+1${input}`;
    }
    return input;
  };

  switch (type) {
    case 'open':
      switch (meta?.behavior) {
        case 'phoneInput':
          return (
            <MuiPhoneNumber
              defaultCountry={'us'}
              onlyCountries={['us']}
              value={getPhoneNumberValue(inputValue as string)}
              disableAreaCodes={true}
              onChange={(event) => {
                onChange(event);
              }}
              sx={{
                width: '100%',
                paddingBottom: '8px',
                paddingTop: '8px',
              }
              }
            />
          );
        default:
          return (
            <StyledTextField
              defaultValue={inputValue}
              onChange={(event) => {
                onChange(event);
              }}
              placeholder={!isOutlined ? getTranslation(placeholderText) : ''}
              variant={isOutlined ? 'outlined' : 'standard'}
              id={placeholderText}
              disabled={textFieldDisabled}
              onBlur={onBlur as () => void}
              sx={{
                ...(isOutlined ? {
                  borderRadius: theme.spacing(1.5),
                  border: '1px solid var(--Base-Color-Neutral-N15, #E2E2E4)',
                  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
                  paddingTop: theme.spacing(0),
                  paddingBottom: theme.spacing(0),
                } : {}),
                '& .MuiInput-underline:before': {
                  borderBottomColor: theme.palette.grey3.main,
                },
              }}
              InputProps={{
                ...(isOutlined ? {
                  sx: {
                    '.MuiOutlinedInput-input': {
                      padding: theme.spacing(1.875),
                    },
                  },
                } : {}),
              }}
              data-testid={dataTestId}
            />
          );
      }
    case 'multichoice':
      switch (meta?.behavior) {
        case 'autoComplete':
          return (
            (groupAutoComplete) ?
              <GroupAutocompleteComponent
                options={options}
                selectedValues={selectedOptions}
                placeholderText={getTranslation(placeholderText)}
                onChange={(newValue) => onChange(newValue)}
                onBlur={onBlur as () => void}
                data-testid={dataTestId}
              /> : <QuestionComponentAutocomplete
                classes={classes}
                inputValue={inputValue as QuestionOption || null}
                options={options}
                placeholderText={getTranslation(placeholderText)}
                onChange={(newValue) => onChange(newValue)}
                onBlur={onBlur as () => void}
                calledFromOnboarding={calledFromOnboarding}
                data-testid={dataTestId}
              />
          );
        case 'dragAndDrop':
          return (
            <DraggableListComponent
              options={options}
              onChange={onChange}
              data-testid={dataTestId}
            />
          );
        case 'imageOption':
          return (
            <ImageOptionComponent
              options={options}
              onChange={onChange}
              data-testid={dataTestId}
            />
          );
        case 'groupDropdown':
          return (
            <GroupDropdownComponent
              options={options}
              onChange={onChange}
              subTitle={subTitle}
              defaultValues={groupDropDownDefaultValues as QuestionOption[]}
              borderStyle={borderStyle}
              calledFromOnboarding={calledFromOnboarding}
              data-testid={dataTestId}
            />
          );
        default:
          return null;
      }
    case 'poll':
      return (
        <PollQuestionComponent
          options={options}
          onClick={onChange}
          data-testid={dataTestId}
        />
      );
    case 'singleSelect':
      return (
        <Select
          defaultValue={inputValue}
          onChange={onChange}
          sx={{
            width: '100%',
          }}
          data-testid={dataTestId}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onChange={onChange}
            >
              {option.display}
            </MenuItem>
          ))}
        </Select>
      );
    default:
      return null;
  }
};

export default QuestionComponent;
