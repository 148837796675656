import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Alert, Snackbar, useTheme } from '@mui/material';
import { ReactComponent as RewardsIcon } from '../assets/images/welcome_modal_rewards.svg';
import { ReactComponent as ResourcesIcon } from '../assets/images/welcome_modal_resources.svg';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { ContinueBtn, WelcomeModalPartnerSelection } from './WelcomeModalPartnerSelection';
import { FollowTopicsCard } from '../components/Content/FollowTopicsCard';
import { useUpdateMemberConfigByKey } from './useUpdateMemberConfigByKey';
import { useAppSelector } from '../store/hooks';
import { selectMemberConfig } from '../store/slices/dashboardSlice';
import { useOnboardEventTrackingWithHeap } from './useOnboardEventTrackingWithHeap';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { TestIds } from '../assets/testIds';

interface WelcomeModalProps {
  openModal: boolean;
  setOpenModal: (val:boolean) => void;
}

export function WelcomeModal({ openModal, setOpenModal }:WelcomeModalProps) {
  const { t } = useTranslation();
  const trackOnboardEventsWithHeap = useOnboardEventTrackingWithHeap();
  const { solutionOrgSpecificAppName } = useSolutionOrg();
  const { memberId } = useAppSelector(selectMemberSession);
  const storeMemberConfig = useAppSelector(selectMemberConfig);
  const [currentIndx, setCurrentIndx] = useState(0);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const updateMemberConfigByKey = useUpdateMemberConfigByKey();
  const theme = useTheme();

  const incrementPage = () => setCurrentIndx(currentIndx + 1);
  const handleModalClose = () => setOpenModal(false);

  const handleErrorClose = () => {
    setOpenErrorSnackbar(false);
  };

  const onDone = () => {
    updateMemberConfigByKey('showWelcomeModal', false, storeMemberConfig?.id as string);
    trackOnboardEventsWithHeap('clicked-done-on-welcome-modal-interest-page', {
      memberId,
    });
    handleModalClose();
  };

  const WelcomeSlide = () => (
      <Box>
        <Typography variant='h8' mb={theme.spacing(1.5)}
        sx={{
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(3),
            fontWeight: 600,
          },
        }}
        >
          {t('welcomeModal.welcomeSlide.heading1', { solutionOrgSpecificAppName })}
        </Typography>
        <Typography mb={theme.spacing(7.5)} mt={theme.spacing(2)}>
          {t('welcomeModal.welcomeSlide.heading2')}
        </Typography>
        <Box>
          <Box display='flex' justifyContent={'center'}>
            <RewardsIcon/>
            <Box
            ml={theme.spacing(2)}
            display='flex'
            flexDirection={'column'}
            sx={{
              width: '40%',
              textAlign: 'left',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}>
              <Typography variant='h3' sx={{ fontWeight: 600, mb: theme.spacing(1) }}>
                {t('welcomeModal.welcomeSlide.rewardsHeading')}
              </Typography>
              <Typography>
                {t('welcomeModal.welcomeSlide.rewardsHeading2')}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' justifyContent={'center'} mt={theme.spacing(6)} mb={theme.spacing(7.5)}>
            <ResourcesIcon/>
            <Box
            ml={theme.spacing(2)}
            display='flex'
            flexDirection={'column'}
            sx={{
              width: '40%',
              textAlign: 'left',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}>
              <Typography variant='h3' sx={{ fontWeight: 600, mb: theme.spacing(1) }}>
                {t('welcomeModal.welcomeSlide.resourcesHeading')}
              </Typography>
              <Typography>
                {t('welcomeModal.welcomeSlide.resourcesHeading2')}
              </Typography>
            </Box>
          </Box>
          <ContinueBtn onBtnClick={() => {
            trackOnboardEventsWithHeap('clicked-continue-on-welcome-modal-welcome-page', {
              memberId,
            });
            incrementPage();
          }
            }
          dataTestId={TestIds.welcomeModal.welcomePageBtn}
          />
        </Box>
      </Box>
  );

  const pages = [
  <WelcomeSlide/>,
  <WelcomeModalPartnerSelection
  onContinue={incrementPage}
  setOpenErrorSnackbar={setOpenErrorSnackbar}
  />,
  <Box>
    <Typography variant='h8' mb={theme.spacing(1.5)}
    sx={{
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        fontWeight: 600,
      },
    }}
    >
      {t('welcomeModal.topicSlide.heading1')}
    </Typography>
    <Typography mb={theme.spacing(7.5)} mt={theme.spacing(2)}>
      {t('welcomeModal.topicSlide.heading2')}
    </Typography>
    <Box
    display={'flex'}
    justifyContent={'center'}
    mb={theme.spacing(7.5)}
    id={'optimus-content-follow-topics-dashboard'}
    >
      <Box sx={{
        width: '60%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      }}>
        <FollowTopicsCard isHomeCard={true} />
      </Box>
    </Box>
    <ContinueBtn
    btnText={t('button.done') as string}
    onBtnClick={onDone}
    dataTestId={TestIds.welcomeModal.interestPageBtn}
    />
  </Box>,
  ];

  return (
      <Modal
        open={openModal}
        onClose={handleModalClose} //
        aria-labelledby='Welcome Modal'
        aria-describedby='Shown to every user the first on edge after onboarding'
        slotProps={{
          backdrop: {
            onClick: (event) => event.stopPropagation(), // Prevent closing on backdrop click
          },
        }}
      >
        <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: theme.palette.common.white,
          borderRadius: theme.spacing(2.5),
          p: theme.spacing(6),
          outline: 0, // Disable the focus outline
          maxWidth: theme.spacing(93.75),
          width: '100%',
          textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
            pl: theme.spacing(2),
            pr: theme.spacing(2),
          },
        }}
        >
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={4000}
            onClose={handleErrorClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {t('error.generalMessage')}
            </Alert>
          </Snackbar>
          <Box mb={theme.spacing(6)}>
          {[...Array(3).keys()].map((val) => (
            <Box
            display='inline-block'
              key={val}
              sx={{
                height: '10px',
                width: '10px',
                backgroundColor: val <= currentIndx ? theme.palette.secondary.dark :
                  theme.palette.color19.main,
                borderRadius: theme.spacing(12.5),
                marginRight: theme.spacing(5),
                ...(val + 1 === 3 && { marginRight: theme.spacing(0) }),
              }}
            />
          ))}
         </Box>
         {pages[currentIndx]}
        </Box>
      </Modal>
  );
}
