import { useOnboardEventTracking } from './useOnboardEventTracking';

export const useOnboardEventTrackingWithHeap = () => {
  const trackOnboardEvent = useOnboardEventTracking();

  return (
    eventName: string,
    detail?: { [key: string]: string | number | boolean },
  ) => {
    trackOnboardEvent(eventName, detail);
    if (global.heap) {
      global.heap.track(`member-${eventName}`, detail || {});
    }
  };
};
