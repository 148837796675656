import { useTheme } from '@mui/material';
import CallToActionButton from '../../../../common/CallToActionButton';

interface CustomButtonProps {
  onBtnClick?: () => void;
  disabled?: boolean;
  btnText: string | JSX.Element;
  smallerMargin?: boolean;
  type: 'button' | 'submit' | 'reset' | undefined
}

export const CustomButton = ({
  onBtnClick,
  btnText,
  disabled,
  smallerMargin,
  type,
}:CustomButtonProps) => {
  const theme = useTheme();

  return (<CallToActionButton
      text={btnText}
      type={type}
      onBtnClick={onBtnClick}
      sx={{
        position: 'static',
        width: '100%',
        marginTop: smallerMargin ? theme.spacing(0) : theme.spacing(2.5),
        marginBottom: smallerMargin ? theme.spacing(2.5) : theme.spacing(0),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        fontWeight: '600',
        fontSize: theme.spacing(2.3),
        background: theme.palette.secondary.dark,
        borderRadius: theme.spacing(1.5),
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '&:disabled': {
          backgroundColor: theme.palette.secondary.dark,
        },
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          marginLeft: '0px',
        },
      }}
      disable={disabled}
    />);
};
