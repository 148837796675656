/* eslint-disable no-control-regex */
export const CompanyInformationQuestionTag = 'company info';
export const PersonalInformationQuestionTag = 'personal info';
export const CompanyRoleQuestionTag = 'company role';
export const AccountInformationQuestionTag = 'account info';
export const BusinessInformationQuestionTag = 'business info';
export const PartnersInformationQuestionTag = 'partners info';
export const JoistSolutionOrgName = 'joist';
export const ServiceFusionSolutionOrgName = 'service-fusion';
export const InvoiceSimpleSolutionOrgName = 'invoice-simple';
export const RoofsnapSolutionOrgName = 'roofsnap';
export const KickservSolutionOrgName = 'kickserv';
export const OnboardingEventType = 'onboarding';
export const ContentEventType = 'content';
export const FeedEventType = 'feed';
export const SearchEventType = 'search';
export const roleAtCompany = 'Role at company';
export type SolutionOrgName = typeof JoistSolutionOrgName |
  typeof ServiceFusionSolutionOrgName |
  typeof InvoiceSimpleSolutionOrgName |
  typeof RoofsnapSolutionOrgName |
  typeof KickservSolutionOrgName |
  undefined
export const emailRegex = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/gm;
export const SRTCode = 'SRT';
export const JoistCode = 'JOIST';
export const JOIST_MEMBER_PARTNER_RELATION_EMAIL_RESPONSE = 'JOIST_MEMBER_PARTNER_RELATION_EMAIL_RESPONSE';
export const JOIST_EVENT_BUFFER_RESPONSE = 'JOIST_EVENT_BUFFER_RESPONSE';
export const JOIST_READY_FOR_EVENT_BUFFER_REQUEST = 'JOIST_READY_FOR_EVENT_BUFFER_REQUEST';
export const OPTIMUS_READY_FOR_EVENT_BUFFER_RESPONSE = 'OPTIMUS_READY_FOR_EVENT_BUFFER_RESPONSE';
export const JOIST_COMPLETE_REWARDS_EVERPRO_SIGNUP = 'JOIST_COMPLETE_REWARDS_EVERPRO_SIGNUP';
export const REWARDS_PARTNER_CODE_COMPLETED = 'rewards_partner_code_completed';
export const BEHR = 'behr';

// eslint-disable-next-line no-shadow
export enum ShoppingStatus {
  Shopping = 'yes', // shops with partner
  NotShopping = 'no', // does not shop with partner
}
