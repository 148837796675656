export const TestIds = {
  login: {
    emailTxt: 'login-email',
    loginCodeTxt: 'login-login-code',
    loginBtn: 'login-login-button',
    editEmailAddressLnk: 'login-edit-email-address',
    contactSupportLnk: 'login-contact-support',
  },
  onboarding: {
    enrollBtn: 'onboarding-enroll',
    roleAtCompanyLst: 'onboarding-role-at-company',
    nextBtn: 'onboarding-next',
  },
  welcomeModal: {
    welcomePageBtn: 'welcome-modal-welcome-page-btn',
    partnerSelectPageBtn: 'welcome-modal-partner-select-page-btn',
    homeDepotPartnerSelector: 'welcome-modal-home-depot-partner-selector',
    hdSupplyPartnerSelector: 'welcome-modal-hd-supply-partner-selector',
    interestPageBtn: 'welcome-modal-interest-page-btn',
  },
  followTopics: {
    hiringBtn: 'follow-topics-hiring-btn',
    pricingBtn: 'follow-topics-pricing-btn',
    leadGenerationBtn: 'follow-topics-lead-generation-btn',
    technologyBtn: 'follow-topics-technology-btn',
    marketingBtn: 'follow-topics-marketing-btn',
  },
};
