import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { selectMemberSession, selectOptedToSkipHdEnrollment } from '../store/slices/onboardingSlice';
import { ResponseError, getCompanyEnrollmentInformation, getOnboardingFormAnswers } from '../store/thunks/onboardingThunk';
import { useOnboardEventTracking } from './useOnboardEventTracking';
import {
  HOME_DEPOT_SETUP_OR_SKIP_ROUTE,
  HOME_DEPOT_SETUP_WEB,
  HOME_ROUTE,
  ONBOARDING_BASE_ROUTE,
} from '../routes/namedRoutes';
import { getHomeDepotMemberRelation } from '../store/thunks/dashboardThunk';
import { useTracking } from './useTracking';
import { BusinessInformationQuestionTag, ShoppingStatus } from './constants';

interface useCompanyEnrollmentInfoProps {
  calledFromOnboardingComponent: boolean;
  redirectPath?: string;
  calledFromHomeDepotSetUpWeb?: boolean
  calledFromHomeDepotSetUpIframe?: boolean;
}

export function useCompanyEnrollmentInfo({
  calledFromOnboardingComponent,
  calledFromHomeDepotSetUpWeb,
  calledFromHomeDepotSetUpIframe,
  redirectPath,
}: useCompanyEnrollmentInfoProps) {
  const trackOnboardEvent = useOnboardEventTracking();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trackEvent = useTracking();
  const location = useLocation();
  const { envRewardProgramId, isIframe, envSolutionOrgFormId } = useSolutionOrg();
  const memberSession = useAppSelector(selectMemberSession);
  const optedToSkipHdEnrollment = useAppSelector(selectOptedToSkipHdEnrollment);

  useEffect(() => {
    const getCompanyEnrollmentInformationResponse = dispatch(getCompanyEnrollmentInformation(
      {
        rewardProgramId: envRewardProgramId as string,
        memberId: memberSession.memberId,
      },
    ))
      .then(unwrapResult);
    const getOnboardingFormAnswersResponse = dispatch(getOnboardingFormAnswers(
      {
        formId: (envSolutionOrgFormId as string) || '',
        trackEvent,
      },
    ))
      .then(unwrapResult);

    Promise.all([
      getCompanyEnrollmentInformationResponse,
      getOnboardingFormAnswersResponse,
    ])
      .then(([companyEnrollmentInfo, onboardingFormAnswers]) => {
        const businessFormAnswers = onboardingFormAnswers?.filter(
          (item) => item.question.tags?.includes(BusinessInformationQuestionTag),
        ) || [];

        // Check if company already enrolled in reward, and
        // user provided their business answer
        if (companyEnrollmentInfo?.status && !!businessFormAnswers?.length) {
          // Get member home depot partner relation, if it exists
          dispatch(getHomeDepotMemberRelation())
            .then(unwrapResult)
            .then((memberHdRelation) => {
              // check if there's a user initiated home depot enrollment (e.g from rewards or home)
              const { userInitiatedHdEnrollment } = location.state || {};

              const isRequiredToViewHomeDepotEnrollmentPrompt =
                (memberHdRelation?.shoppingStatus !== ShoppingStatus.NotShopping ||
                  userInitiatedHdEnrollment
                ) &&
                !(memberHdRelation?.partnerMemberKey ||
                  memberHdRelation?.partnerMemberEmail ||
                  memberHdRelation?.partnerCodeEnteredInMobile) &&
                !optedToSkipHdEnrollment;

              // Redirect to home depot setup if:
              // 1. member is required to view home depot enrollment prompt
              if (isRequiredToViewHomeDepotEnrollmentPrompt) {
                // web user and hook not called from home depot setup web page
                if (!isIframe && !calledFromHomeDepotSetUpWeb) {
                  navigate(HOME_DEPOT_SETUP_WEB);
                }

                // iframe user and hook not called from home depot setup iframe page
                if (isIframe && !calledFromHomeDepotSetUpIframe) {
                  navigate(HOME_DEPOT_SETUP_OR_SKIP_ROUTE);
                }
              } else if (
                calledFromHomeDepotSetUpWeb ||
                calledFromHomeDepotSetUpIframe ||
                calledFromOnboardingComponent) {
                navigate(redirectPath || HOME_ROUTE);
              }
            })
            .catch((error: ResponseError) => {
              trackOnboardEvent('get-member-hd-relation-failed', { error }, error.apiResponseErrorStatus);
            });
          // user's company has not yet enrolled in reward
          // or the user is a web user and hasn't answered the business form questions
        } else if (!calledFromOnboardingComponent) {
          navigate(redirectPath || ONBOARDING_BASE_ROUTE);
        }
      })
      .catch((error: ResponseError) => {
        trackOnboardEvent('get-company-enrollment-information-failed', { error }, error.apiResponseErrorStatus);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
