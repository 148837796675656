/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../storeSetup';
import {
  getOnboardingForm,
  getMemberInformation,
  enrollMemberToReward,
  updatePartnersOfMember,
  getCompanyRoles,
  getOnboardingFormAnswers,
  getCompanyEnrollmentInformation,
} from '../thunks/onboardingThunk';
import { CompanyRoleOptions, Question } from '../../common/models/Question';
import { Member } from '../../common/models/Member';
import {
  BusinessInformationQuestionTag,
  CompanyInformationQuestionTag,
  PartnersInformationQuestionTag,
  PersonalInformationQuestionTag,
  CompanyRoleQuestionTag,
} from '../../common/constants';
import { OnboardingForm } from '../../common/models/OnboardingForm';
import { Answer } from '../../common/models/Answer';
import { FormAnswer } from '../../common/models/FormAnswer';
import { loginMember } from '../thunks/authenticationThunk';
import { Partner } from '../../common/models/Partner';
import { CompanyEnrollment } from '../../common/models/EnrollmentResponse';

export interface MemberSession {
  accessToken: string;
  tokenExpiry: string | null;
  refreshToken: string | null;
  rewardProgramId: string;
  memberId: string;
  memberEmail: string;
  solutionOrgId: string;
  memberDeviceType: string;
  memberDeviceVersion: string;
  sessionId: string;
}

export interface MemberPartnerRelation {
  memberEmail: string;
  partnerId: string;
}
export interface OptimusEvent {
  name: string;
  detail?: {
    [k: string]: unknown;
  }
}

interface OnboardingState {
  partnerOnboardingDone: boolean;
  currentFormIndex: number;
  isFormValid: boolean;
  onboardingForm: OnboardingForm;
  getOnboardingFormStatus: 'idle' | 'pending';
  companyInformationQuestions: Question[];
  businessInformationQuestions: Question[];
  businessInformationAnswers: FormAnswer[];
  getOnboardingFormAnswersStatus: 'idle' | 'pending';
  partnersInformationQuestions?: Question[];
  personalInformationQuestions: Question[];
  companyRoleQuestions: Question[];
  memberInformation: Member;
  getMemberInformationStatus: 'idle' | 'pending';
  memberSession: MemberSession;
  memberPartnerRelation: MemberPartnerRelation;
  enrollMemberToRewardStatus: 'idle' | 'pending';
  updatePartnersOfMemberStatus: 'idle' | 'pending';
  onboardingFormAnswers: Answer[];
  companyRoleOptions: CompanyRoleOptions[];
  getCompanyRoleOptionsStatus: 'idle' | 'pending';
  selectedPartners: Partner[];
  partnerInformationBtnText?: string;
  onboardingLandingPageViewDone: boolean;
  onboardingPartnerPageDone: boolean;
  companyEnrollmentInformation: CompanyEnrollment | null;
  getCompanyEnrollmentInformationStatus: 'idle' | 'pending';
  navigationFromOnboarding: boolean;
  isBackToPrevStep: boolean;
  optedToSkipHdEnrollment: boolean;
}

// Define the initial state using that type
const initialState: OnboardingState = {
  memberSession: {
    accessToken: '',
    tokenExpiry: null,
    refreshToken: null,
    rewardProgramId: '',
    memberId: '',
    memberEmail: '',
    solutionOrgId: '',
    memberDeviceType: '',
    memberDeviceVersion: '',
    sessionId: '',
  },
  memberPartnerRelation: {
    memberEmail: '',
    partnerId: '',
  },
  partnerOnboardingDone: false,
  isFormValid: true,
  memberInformation: {
    id: '',
    companyName: '',
    companyAddressStreet: '',
    companyAddressCity: '',
    companyAddressState: '',
    companyAddressZipCode: '',
    companyEmail: '',
    companyWebsiteUrl: '',
    // companyPhoneNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    companyRoleId: '',
  },
  getMemberInformationStatus: 'pending',
  currentFormIndex: 1,
  onboardingForm: {
    id: '',
    name: '',
  },
  getOnboardingFormStatus: 'pending',
  companyInformationQuestions: [
    {
      id: '',
      title: '',
      type: 'multichoice',
    },
  ],
  businessInformationQuestions: [
    {
      id: '',
      title: '',
      type: 'multichoice',
    },
  ],
  businessInformationAnswers: [],
  getOnboardingFormAnswersStatus: 'pending',
  personalInformationQuestions: [
    {
      id: '',
      title: '',
      type: 'multichoice',
    },
  ],
  companyRoleQuestions: [
    {
      id: '',
      title: '',
      type: 'multichoice',
    },
  ],
  companyRoleOptions: [
    {
      id: '',
      name: '',
      description: '',
    },
  ],
  getCompanyRoleOptionsStatus: 'pending',
  enrollMemberToRewardStatus: 'idle',
  updatePartnersOfMemberStatus: 'idle',
  onboardingFormAnswers: [],
  selectedPartners: [],
  onboardingLandingPageViewDone: false,
  onboardingPartnerPageDone: false,
  companyEnrollmentInformation: null,
  getCompanyEnrollmentInformationStatus: 'idle',
  navigationFromOnboarding: false,
  isBackToPrevStep: false,
  optedToSkipHdEnrollment: false,
};

const memberInformationMapper = (payload: Member) => {
  const memberInformation = {
    id: payload.id,
    businessMainIndustry: payload.businessMainIndustry,
    companyName: payload.companyName,
    companyAddressStreet: payload.companyAddressStreet,
    companyAddressCity: payload.companyAddressCity,
    companyAddressState: payload.companyAddressState,
    companyAddressCountry: payload.companyAddressCountry,
    companyAddressZipCode: payload.companyAddressZipCode,
    companyEmail: payload.companyEmail,
    companyWebsiteUrl: payload.companyWebsiteUrl,
    companyRoleId: payload.companyRoleId,
    companyPhoneNumber: payload.companyPhoneNumber,
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    companyRole: payload.companyRole,
    company: payload.company,
  };
  return memberInformation;
};

const sortOnboardingQuestions = (questions: Question[]) => {
  const result = questions
    .sort((a, b) => (Number(a.meta?.order) - Number(b.meta?.order)));
  return result;
};

export const OnboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    addMemberInformation: (state, action: PayloadAction<Member>) => {
      state.memberInformation = memberInformationMapper(action.payload);
    },
    addSelectedPartners: (state, action: PayloadAction<Partner[]>) => {
      state.selectedPartners = action.payload;
    },
    addPartnerInformationBtnText: (state, action: PayloadAction<string>) => {
      state.partnerInformationBtnText = action.payload;
    },
    addMemberSession: (state, action: PayloadAction<MemberSession>) => {
      state.memberSession = action.payload;
    },
    addAccessToken: (state, action: PayloadAction<string>) => {
      state.memberSession.accessToken = action.payload;
    },
    addRefreshToken: (state, action: PayloadAction<string | null>) => {
      state.memberSession.refreshToken = action.payload;
    },
    addTokenExpiry: (state, action: PayloadAction<string>) => {
      state.memberSession.tokenExpiry = action.payload;
    },
    addRewardProgramId: (state, action: PayloadAction<string>) => {
      state.memberSession.rewardProgramId = action.payload;
    },
    addMemberId: (state, action: PayloadAction<string>) => {
      state.memberSession.memberId = action.payload;
    },
    addSolutionOrgId: (state, action: PayloadAction<string>) => {
      state.memberSession.solutionOrgId = action.payload;
    },
    addMemberDeviceType: (state, action: PayloadAction<string>) => {
      state.memberSession.memberDeviceType = action.payload;
    },
    addMemberDeviceVersion: (state, action: PayloadAction<string>) => {
      state.memberSession.memberDeviceVersion = action.payload;
    },
    addSessionId: (state, action: PayloadAction<string>) => {
      state.memberSession.sessionId = action.payload;
    },
    addMemberPartnerRelation: (state, action: PayloadAction<MemberPartnerRelation>) => {
      state.memberPartnerRelation = action.payload;
    },
    addMemberPartnerRelationMemberEmail: (state, action: PayloadAction<string>) => {
      state.memberPartnerRelation.memberEmail = action.payload;
    },
    addMemberPartnerRelationPartnerId: (state, action: PayloadAction<string>) => {
      state.memberPartnerRelation.partnerId = action.payload;
    },
    changeFormIndex: (state, action: PayloadAction<number>) => {
      state.currentFormIndex = action.payload;
    },
    setIsBackToPrevStep: (state, action: PayloadAction<boolean>) => {
      state.isBackToPrevStep = action.payload;
    },
    setOptedToSkipHdEnrollment: (state, action: PayloadAction<boolean>) => {
      state.optedToSkipHdEnrollment = action.payload;
    },
    updateFormValidState: (state, action: PayloadAction<boolean>) => {
      state.isFormValid = action.payload;
    },
    updatePartnerOnboardingState: (state, action: PayloadAction<boolean>) => {
      state.partnerOnboardingDone = action.payload;
    },
    updateOnboardingLandingPageViewDone: (state, action: PayloadAction<boolean>) => {
      state.onboardingLandingPageViewDone = action.payload;
    },
    updateOnboardingPartnerPageDone: (state, action: PayloadAction<boolean>) => {
      state.onboardingPartnerPageDone = action.payload;
    },
    addFromAnswers: (state, action: PayloadAction<Answer[]>) => {
      state.onboardingFormAnswers = action.payload;
    },
    updateNavigationFromOnboarding: (state, action: PayloadAction<boolean>) => {
      state.navigationFromOnboarding = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMemberInformation.pending, (state, action) => {
        state.getMemberInformationStatus = 'pending';
      })
      .addCase(getMemberInformation.fulfilled, (state, action) => {
        state.memberInformation = memberInformationMapper(action.payload);
        state.getMemberInformationStatus = 'idle';
      })
      .addCase(getMemberInformation.rejected, (state, action) => {
        state.getMemberInformationStatus = 'idle';
      })
      .addCase(getCompanyEnrollmentInformation.pending, (state, action) => {
        state.getCompanyEnrollmentInformationStatus = 'pending';
      })
      .addCase(getCompanyEnrollmentInformation.fulfilled, (state, action) => {
        state.companyEnrollmentInformation = action.payload;
        state.getCompanyEnrollmentInformationStatus = 'idle';
      })
      .addCase(getCompanyEnrollmentInformation.rejected, (state, action) => {
        state.getCompanyEnrollmentInformationStatus = 'idle';
      })
      .addCase(getCompanyRoles.pending, (state, action) => {
        state.getCompanyRoleOptionsStatus = 'pending';
      })
      .addCase(getCompanyRoles.fulfilled, (state, action) => {
        state.companyRoleOptions = action.payload;
        state.getCompanyRoleOptionsStatus = 'idle';
      })
      .addCase(getCompanyRoles.rejected, (state, action) => {
        state.getCompanyRoleOptionsStatus = 'idle';
      })
      .addCase(getOnboardingForm.pending, (state, action) => {
        state.getOnboardingFormStatus = 'pending';
      })
      .addCase(getOnboardingForm.fulfilled, (state, action) => {
        const companyInformationQuestions =
          action.payload.questions?.filter(
            (item) => item.tags?.includes(CompanyInformationQuestionTag),
          ) || [];
        const businessInformationQuestions =
          action.payload.questions?.filter(
            (item) => item.tags?.includes(BusinessInformationQuestionTag),
          ) || [];
        const partnersInformationQuestions =
          action.payload.questions?.filter(
            (item) => item.tags?.includes(PartnersInformationQuestionTag),
          ) || [];
        const personalInformationQuestions =
          action.payload.questions?.filter(
            (item) => (item.tags?.includes(PersonalInformationQuestionTag)),
          ) || [];
        const companyRoleQuestions =
          action.payload.questions?.filter(
            (item) => item.tags?.includes(CompanyRoleQuestionTag),
          ) || [];
        state.companyInformationQuestions = sortOnboardingQuestions(companyInformationQuestions);
        state.businessInformationQuestions = sortOnboardingQuestions(businessInformationQuestions);
        state.partnersInformationQuestions = sortOnboardingQuestions(partnersInformationQuestions);
        state.personalInformationQuestions = sortOnboardingQuestions(personalInformationQuestions);
        state.companyRoleQuestions = sortOnboardingQuestions(companyRoleQuestions);
        state.onboardingForm = action.payload;
        state.getOnboardingFormStatus = 'idle';
      })
      .addCase(getOnboardingForm.rejected, (state, action) => {
        state.getOnboardingFormStatus = 'idle';
      })
      .addCase(getOnboardingFormAnswers.pending, (state, action) => {
        state.getOnboardingFormAnswersStatus = 'pending';
      })
      .addCase(getOnboardingFormAnswers.fulfilled, (state, action) => {
        state.businessInformationAnswers =
          action.payload.filter(
            (item) => item.question.tags?.includes(BusinessInformationQuestionTag),
          ) || [];
        state.getOnboardingFormAnswersStatus = 'idle';
      })
      .addCase(getOnboardingFormAnswers.rejected, (state, action) => {
        state.getOnboardingFormAnswersStatus = 'idle';
      })
      .addCase(enrollMemberToReward.pending, (state, action) => {
        state.enrollMemberToRewardStatus = 'pending';
      })
      .addCase(enrollMemberToReward.fulfilled, (state, action) => {
        state.enrollMemberToRewardStatus = 'idle';
      })
      .addCase(enrollMemberToReward.rejected, (state, action) => {
        state.enrollMemberToRewardStatus = 'idle';
      })
      .addCase(updatePartnersOfMember.pending, (state, action) => {
        state.updatePartnersOfMemberStatus = 'pending';
      })
      .addCase(updatePartnersOfMember.fulfilled, (state, action) => {
        state.updatePartnersOfMemberStatus = 'idle';
      })
      .addCase(updatePartnersOfMember.rejected, (state, action) => {
        state.updatePartnersOfMemberStatus = 'idle';
      })
      .addCase(loginMember.fulfilled, (state, action) => {
        state.memberSession.accessToken = action.payload.accessToken;
        state.memberSession.tokenExpiry = action.payload.tokenExpiry;
        state.memberSession.refreshToken = action.payload.refreshToken;
        state.memberSession.memberId = action.payload.memberId;
        state.memberSession.memberEmail = action.payload.memberEmail;
        state.memberSession.solutionOrgId = action.payload.solutionOrgId;
      });
  },
});

export const {
  addMemberDeviceType,
  addMemberDeviceVersion,
  addSessionId,
  addMemberInformation,
  addSelectedPartners,
  addPartnerInformationBtnText,
  addMemberSession,
  addAccessToken,
  addRefreshToken,
  addTokenExpiry,
  addMemberId,
  addRewardProgramId,
  addSolutionOrgId,
  addMemberPartnerRelation,
  addMemberPartnerRelationMemberEmail,
  addMemberPartnerRelationPartnerId,
  changeFormIndex,
  updateFormValidState,
  updatePartnerOnboardingState,
  updateOnboardingLandingPageViewDone,
  updateOnboardingPartnerPageDone,
  addFromAnswers,
  updateNavigationFromOnboarding,
  setIsBackToPrevStep,
  setOptedToSkipHdEnrollment,
} = OnboardingSlice.actions;

export const selectMemberInformation = (state: RootState) => state.onboarding.memberInformation;
export const selectMemberSession = (state: RootState) => state.onboarding.memberSession;
export const selectMemberPartnerRelation =
  (state: RootState) => state.onboarding.memberPartnerRelation;
export const selectCurrentFormIndex = (state: RootState) => state.onboarding.currentFormIndex;
export const selectCurrentFormValidState = (state: RootState) => state.onboarding.isFormValid;
export const selectEnrollMemberToRewardStatus =
  (state: RootState) => state.onboarding.enrollMemberToRewardStatus;
export const selectUpdatePartnersOfMemberStatus =
  (state: RootState) => state.onboarding.updatePartnersOfMemberStatus;
export const selectPartnerOnboardingState =
  (state: RootState) => state.onboarding.partnerOnboardingDone;
export const selectCompanyInformationQuestions =
  (state: RootState) => state.onboarding.companyInformationQuestions;
export const selectBusinessInformationQuestions =
  (state: RootState) => state.onboarding.businessInformationQuestions;
export const selectPartnersInformationQuestions =
  (state: RootState) => state.onboarding.partnersInformationQuestions;
export const selectPersonalInformationQuestions =
  (state: RootState) => state.onboarding.personalInformationQuestions;
export const selectCompanyRoleQuestions =
  (state: RootState) => state.onboarding.companyRoleQuestions;
export const selectCompanyRoleOptions = (state: RootState) => state.onboarding.companyRoleOptions;
export const selectOnboardingForm = (state: RootState) => state.onboarding.onboardingForm;
export const selectOnboardingFormAnswers =
  (state: RootState) => state.onboarding.onboardingFormAnswers;
export const selectGetMemberInformationStatus =
  (state: RootState) => state.onboarding.getMemberInformationStatus;
export const selectGetCompanyRoleOptionsStatus =
  (state: RootState) => state.onboarding.getCompanyRoleOptionsStatus;
export const selectGetOnboardingFormStatus =
  (state: RootState) => state.onboarding.getOnboardingFormStatus;
export const selectGetOnboardingFormAnswersStatus =
  (state: RootState) => state.onboarding.getOnboardingFormAnswersStatus;
export const selectBusinessInformationAnswers =
  (state: RootState) => state.onboarding.businessInformationAnswers;
export const selectSelectedPartners =
  (state: RootState) => state.onboarding.selectedPartners;
export const selectPartnerInformationBtnText =
  (state: RootState) => state.onboarding.partnerInformationBtnText;
export const selectOnboardingLandingPageViewDone =
  (state: RootState) => state.onboarding.onboardingLandingPageViewDone;
export const selectOnboardingPartnerPageDone =
  (state: RootState) => state.onboarding.onboardingPartnerPageDone;
export const selectCompanyEnrollmentInformation =
  (state: RootState) => state.onboarding.companyEnrollmentInformation;
export const selectGetCompanyEnrollmentInformationStatus =
  (state: RootState) => state.onboarding.getCompanyEnrollmentInformationStatus;
export const selectNaviagtionFromOnboarding =
  (state: RootState) => state.onboarding.navigationFromOnboarding;
export const selectIsBackToPrevStep =
  (state: RootState) => state.onboarding.isBackToPrevStep;
export const selectOptedToSkipHdEnrollment =
  (state: RootState) => state.onboarding.optedToSkipHdEnrollment;

export default OnboardingSlice.reducer;
