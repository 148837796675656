import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { updateMemberConfig } from '../api/dashboardAPI';
import { getMemberConfig } from '../store/thunks/dashboardThunk';

<p></p>;

export function useUpdateMemberConfigByKey() {
  const [searchParams] = useSearchParams();
  const memberSession = useAppSelector(selectMemberSession);
  const dispatch = useAppDispatch();
  const memberId = memberSession.memberId || searchParams.get('memberId');
  const updateMemberConfigByKey = async (
    key: string,
    showStatus: boolean,
    memberConfigId:string,
  ) => {
    updateMemberConfig(
      { [key]: showStatus },
          memberId as string,
          memberConfigId,
    )
      .then(() => {
        dispatch(getMemberConfig());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return updateMemberConfigByKey;
}
