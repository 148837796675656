/* eslint-disable no-nested-ternary */
import {
  Alert, Box, CircularProgress, Container, Link, Snackbar, TextField, Typography, useTheme,
} from '@mui/material';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CallToActionButton from '../../common/CallToActionButton';
import { generateTemporaryLoginCode } from '../../api/loginAPI';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loginMember } from '../../store/thunks/authenticationThunk';
import { addSessionId, selectMemberSession } from '../../store/slices/onboardingSlice';
import { MemberLoginResponseMessage, MemberLoginType } from '../../common/models/Member';
import { useLogo } from '../../config/useLogo';
import { emailRegex } from '../../common/constants';
import { TestIds } from '../../assets/testIds';

export interface LoginProps {
  afterLoginPath?: string
}
interface IFormInputs {
  email: string;
  loginCodes: {
    value: string;
  }[]
}

export function Login({ afterLoginPath }: LoginProps) {
  const {
    handleSubmit,
    setValue,
    control,
    formState,
    getValues,
  } = useForm<IFormInputs>({ mode: 'onChange' });
  const [isLogging, setIsLogging] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLoginCode, setShowLoginCode] = useState(false);
  const [loginError, setLoginError] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sessionId } = useAppSelector(selectMemberSession);
  const [searchParams] = useSearchParams();
  const initialized = useRef(false);
  const theme = useTheme();
  const { secondaryOne: Logo } = useLogo();
  const urlAfterLoginPath = searchParams.get('afterLoginPath');
  const solutionOrg = searchParams.get('solutionOrg');
  const computedAfterLoginPath = useMemo(
    () => urlAfterLoginPath || afterLoginPath || '/home',
    [urlAfterLoginPath, afterLoginPath],
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'loginCodes', // Provide a valid name for the field array
  });

  const onSubmit = handleSubmit(async (data: IFormInputs) => {
    if (data.email) {
      setIsLogging(true);
      if (data.loginCodes.length === 0) {
        try {
          const loginSessionResponse = await generateTemporaryLoginCode(data.email);
          if (loginSessionResponse?.id) {
            dispatch(addSessionId(loginSessionResponse.id));
          }
          append({ value: '' });
          setShowLoginCode(true);
          setIsLogging(false);
        } catch (err) {
          setOpenSuccessSnackbar(false);
          setOpenErrorSnackbar(true);
          console.log(err);
          setIsLogging(false);
        }
      } else {
        dispatch(loginMember({
          type: MemberLoginType.OTP,
          password: data.loginCodes[0].value,
          sessionId,
        }))
          .then(unwrapResult)
          .then(() => {
            setOpenSuccessSnackbar(true);
            setOpenErrorSnackbar(false);
            setIsLogging(false);
            navigate(computedAfterLoginPath);
          })
          .catch((err: unknown) => {
            const errStatus = (err as AxiosError)?.response?.status;
            if (errStatus === 400 || errStatus === 403) {
              const errMsg =
                (((err as AxiosError)?.response?.data) as { message: string })?.message;
              if (errMsg === MemberLoginResponseMessage.EXPIRED_OTP ||
                errMsg === MemberLoginResponseMessage.MISSING_SESSION_ID) {
                setOpenSuccessSnackbar(false);
                setOpenErrorSnackbar(true);
                remove(0);
                setShowLoginCode(false);
              } else if (errMsg === MemberLoginResponseMessage.INVALID_OTP) {
                setLoginError('Invalid login code');
              } else if (errMsg.includes(MemberLoginResponseMessage.No_SESSION_ID_FOUND)) {
                setLoginError('Invalid login code');
              } else {
                setLoginError(errMsg);
              }
            } else {
              setOpenSuccessSnackbar(false);
              setOpenErrorSnackbar(true);
            }
            setIsLogging(false);
            console.log(err);
          });
      }
    }
  });

  // handle email prefill
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const email = searchParams.get('email');
      if (email) {
        setValue('email', email);
        onSubmit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  const openContactSupportForm = () => {
    if (solutionOrg) {
      navigate(`/contact-support?solutionOrg=${solutionOrg}`);
    } else {
      navigate('/contact-support');
    }
  };

  return (
    <Container
      maxWidth='sm'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '80vh',
        pr: theme.spacing(3),
        pl: theme.spacing(3),
      }}
    >
      <>
        <Snackbar
          open={openSuccessSnackbar}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {t('success.login')}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {t('error.generalMessage')}
          </Alert>
        </Snackbar>
        <Box sx={{ textAlign: 'center' }}>
          {Logo && <Logo />}
        </Box>
        <Box
          sx={{
            background: theme.palette.common.white,
            borderRadius: theme.spacing(2.25),
            paddingLeft: theme.spacing(3.5),
            paddingRight: theme.spacing(3.5),
            paddingTop: theme.spacing(3),
            marginTop: theme.spacing(3),
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              mb: theme.spacing(2),
            }}
            variant='h4'>{t('login.heading')}</Typography>
          <Typography
            sx={{
              color: theme.palette.grey56.main,
              textAlign: 'left',
            }}
            variant='body1'>
            {showLoginCode ? `${t('login.codeInput')} ${getValues('email')}` : t('login.emailInput')}
          </Typography>

          <Box
            sx={{ marginTop: theme.spacing(2) }}
          >
            <form onSubmit={onSubmit}>
              {!showLoginCode ?
                <Box>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={''}
                    render={({
                      field: {
                        onChange, onBlur, value,
                      },
                    }) => <TextField
                        value={value} // Set the value directly
                        onChange={(event) => {
                          onChange(event);
                          remove(0);
                          setShowLoginCode(false);
                          setLoginError('');
                        }}
                        onBlur={onBlur}
                        placeholder={'Email address'}
                        data-testid={TestIds.login.emailTxt}
                        InputProps={{
                          sx: {
                            borderRadius: theme.spacing(1),
                            border: `1px solid ${theme.palette.color8.main}`,
                            background: theme.palette.color7.main,
                            fontWeight: '400',
                            fontSize: theme.spacing(2.25),
                            paddingLeft: theme.spacing(2),
                          },
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root.Mui-focused': {
                            '& > fieldset': {
                              borderColor: formState?.errors?.email && formState.errors.email.type !== 'required' ?
                                theme.palette.primary.main : theme.palette.secondary.main,
                            },
                          },
                        }}
                      />}
                    rules={{
                      required: 'email is required',
                      pattern: {
                        value: emailRegex,
                        message: `${t('error.invalidEmail2')}`,
                      },
                    }}
                  />
                  {(formState?.errors?.email && formState.errors.email.type !== 'required') &&
                    <Alert
                      icon={false}
                      sx={{
                        background: theme.palette.common.white,
                        color: theme.palette.error.main,
                        p: 0,
                      }}
                      severity='error'>
                      {formState?.errors.email.message}
                    </Alert>}
                </Box> : <>
                  {fields.map((loginCode, index) => (
                    <Box key={index}>
                      <Box>
                        <Controller
                          name={`loginCodes.${index}.value`}
                          control={control}
                          defaultValue={loginCode.value} // Set default value for each login code
                          render={({ field: { onChange, onBlur } }) => (
                            <TextField
                              onChange={(event) => {
                                onChange(event);
                                setLoginError('');
                              }}
                              onBlur={onBlur}
                              placeholder="Login code"
                              data-testid={TestIds.login.loginCodeTxt}
                              InputProps={{
                                sx: {
                                  borderRadius: theme.spacing(1),
                                  border: `1px solid ${theme.palette.color8.main}`,
                                  background: theme.palette.color7.main,
                                  fontWeight: '400',
                                  fontSize: theme.spacing(2.25),
                                  paddingLeft: theme.spacing(2.125),
                                },
                              }}
                              sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                  '& > fieldset': {
                                    borderColor: theme.palette.secondary.main,
                                  },
                                },
                              }}
                            />
                          )}
                          rules={{
                            required: 'Login code is required',
                          }}
                        />
                      </Box>
                      {formState?.errors?.loginCodes?.[index]?.value && (
                        <Alert
                          icon={false}
                          sx={{
                            background: theme.palette.common.white,
                            color: theme.palette.error.main,
                            p: 0,
                          }}
                          severity="error"
                        >
                          {formState?.errors?.loginCodes?.[index]?.value?.message}
                        </Alert>
                      )}
                    </Box>))}
                </>
              }
              {loginError &&
                <Alert
                  icon={false}
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.error.main,
                    p: 0,
                  }}
                  severity='error'
                >
                  {loginError}
                </Alert>}
              <CallToActionButton
                text={isLogging ?
                  <CircularProgress color="inherit" size={24} /> :
                  (showLoginCode ? `${t('button.login')}` : `${t('button.sendLoginCode')}`)}
                type={'submit'}
                data-testid={TestIds.login.loginBtn}
                sx={{
                  background: theme.palette.secondary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                  '&:disabled': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                  position: 'static',
                  width: '100%',
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(4),
                  paddingTop: theme.spacing(0.5),
                  paddingBottom: theme.spacing(0.5),
                  borderRadius: theme.spacing(1),
                  fontWeight: '600',
                  fontSize: '18.864px',
                  [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    marginLeft: '0px',
                  },
                }}
                disable={!formState.isValid || isLogging}
              />
              {showLoginCode &&
                <Box
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    mb: theme.spacing(4),
                  }}
                >
                  <Link
                    href="#"
                    data-testid={TestIds.login.editEmailAddressLnk}
                    variant='h6'
                    color={theme.palette.secondary.main}
                    onClick={() => {
                      remove(0);
                      setShowLoginCode(false);
                      setLoginError('');
                    }}
                  >
                    {t('login.editEmailAddress')}
                  </Link>
                </Box>
              }
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            mt: theme.spacing(3),
            textAlign: 'center',
          }}
        >
          <Typography variant='body7'>{t('login.loginTroubleMessage')} &nbsp;
            <Link onClick={() => openContactSupportForm()}
              sx={{
                cursor: 'pointer',
              }}
              data-testid={TestIds.login.contactSupportLnk}
              variant='h6' color={theme.palette.secondary.main}>
              {t('login.contactSupport')}</Link>
          </Typography>
        </Box>
      </>
    </Container>
  );
}
