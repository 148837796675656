import {
  Alert,
  Box, Card, CardContent, CircularProgress, FormControl,
  FormControlLabel, List,
  ListItem, ListItemText,
  Radio, RadioGroup, Snackbar, TextField, Typography, useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { AddMemberToPartnerInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';
import OnboardingLayout from './common/OnboardingLayout';
import { ReactComponent as NewTabIcon } from '../../../assets/images/new_tab_icon.svg';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { emailRegex } from '../../../common/constants';
import { updateMemberToPartner } from '../../../store/thunks/dashboardThunk';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectMemberSession, updateNavigationFromOnboarding } from '../../../store/slices/onboardingSlice';
import VideoPlayer from '../../../common/VideoPlayer';
import { OnboardHDShopQuestion } from './OnboardHDShopQuestion';
import { CustomButton } from './common/CustomHDSetupButton';
import { PostponeHdSetup } from './common/PostponeHdStep';
import { useOnboardEventTrackingWithHeap } from '../../../common/useOnboardEventTrackingWithHeap';

interface IFormInputs {
  accountStatus: string;
  email: string
}

export const SHOW_SIGN_IN_OR_SIGN_UP_ACTION = 'Sign in or sign up';
export const SHOW_IS_DONE_ACTION = 'Did you complete all the steps?';
export const SHOW_EMAIL_ACTION = 'Enter email';

export function OnboardHDHomeWeb() {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    register, handleSubmit, control, formState, setValue, clearErrors,
  } = useForm<IFormInputs>({ mode: 'onChange' });
  const [showSignInOrSignUp, setShowSignInOrSignUp] = useState(false);
  const [showIsDone, setShowIsDone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [hdEmail, setHdEmail] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const trackOnboardEvent = useOnboardEventTracking();
  const { memberId } = useAppSelector(selectMemberSession);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const signInVideoUrl = 'https://d2o7bp9oz3gksv.cloudfront.net/sign_in_flow_044a3d8ae3.mp4';
  const signUpVideoUrl = 'https://d2o7bp9oz3gksv.cloudfront.net/sign_up_flow_beef0edde8.mp4';
  const location = useLocation();
  const [showHDShopQuestion, setShowHDShopQuestion] = useState(!location.state?.fromRewards);
  const trackOnboardEventsWithHeap = useOnboardEventTrackingWithHeap();

  useEffect(() => {
    setShowHDShopQuestion(!location.state?.fromRewards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    calledFromHomeDepotSetUpWeb: true,
  });

  // Watch the selected radio button value
  // const selectedOption = watch('accountStatus');

  const setSecondaryCardState = (triggerAction: string) => {
    switch (triggerAction) {
      case SHOW_SIGN_IN_OR_SIGN_UP_ACTION:
        setShowSignInOrSignUp(true);
        setShowIsDone(false);
        setShowEmail(false);
        break;
      case SHOW_IS_DONE_ACTION:
        setShowIsDone(true);
        setShowEmail(false);
        setShowSignInOrSignUp(false);
        break;
      case SHOW_EMAIL_ACTION:
        setShowEmail(true);
        setShowIsDone(false);
        setShowSignInOrSignUp(false);
        break;
      default:
        break;
    }
  };

  const onSubmit = (data:IFormInputs) => {
    if (data.email) {
      setLoading(true);
      const request: AddMemberToPartnerInput = {
        partnerMemberEmail: data.email,
      };

      trackOnboardEvent('clicked-hd-card-confirm-email', {
        hdEmail: data.email,
      });

      dispatch(updateMemberToPartner({
        memberId,
        partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
        data: request,
      }))
        .then(unwrapResult)
        .then(() => {
          setOpenErrorSnackbar(false);

          // navigate to content home page
          dispatch(updateNavigationFromOnboarding(true));

          const redirectPath = location.state?.fromRewards ? '/rewards' : '/home';
          navigate(redirectPath, {
            state: { fromOnboarding: true },
          });
        })
        .catch((error: unknown) => {
          setOpenErrorSnackbar(true);
          setLoading(false);
        });
    }
  };

  const handleErrorClose = () => {
    setOpenErrorSnackbar(false);
  };

  // Handle the radio button change
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (hdEmail) {
      setHdEmail('');
      setValue('email', ''); // reset the email field
      clearErrors('email'); // Clear any existing validation errors for the email field
    }
    setSelectedOption(e.target.value);
    setSecondaryCardState(SHOW_SIGN_IN_OR_SIGN_UP_ACTION);
    trackOnboardEvent('selected-if-existing-hd-proxtra-account', {
      existingProXtraAccount: e.target.value,
    });
  };

  const onLaunchClick = () => {
    window.open('https://www.homedepot.com/myaccount/proXtraPricing', '_blank');
    setSecondaryCardState(SHOW_IS_DONE_ACTION);
    trackOnboardEvent('clicked-hd-card-sign-in');
    trackOnboardEvent('clicked-hd-card-enter-code');
  };

  const onYesDoneClick = () => {
    setSecondaryCardState(SHOW_EMAIL_ACTION);
    trackOnboardEvent('clicked-hd-card-setup-done');
  };

  const onNotDoneClick = () => {
    setSecondaryCardState(SHOW_SIGN_IN_OR_SIGN_UP_ACTION);
    trackOnboardEvent('clicked-hd-card-setup-reset');
  };

  const SignInOrSignUpCard = () => (
                <Box>
                  <Typography sx={{
                    textAlign: 'left',
                    fontWeight: 600,
                    mb: theme.spacing(2.5),
                    mt: theme.spacing(5),
                  }}>
                  {t('hdSetUpWeb.signInOrSignUp.heading1')}
                  </Typography>
                  <List sx={{ padding: 0, margin: 0 }}>
                    <ListItem sx={{ p: 0, mb: theme.spacing(2.5) }}>
                      <ListItemText
                        primary={
                          <Typography component="span">
                            {selectedOption === 'yes' ?
                              t('hdSetUpWeb.signInOrSignUp.signInText') :
                              t('hdSetUpWeb.signInOrSignUp.signUpText')
                            }
                          </Typography>
                        }
                        sx={{
                          mt: 0,
                          mb: 0,
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0, mb: theme.spacing(2.5) }}>
                      <ListItemText
                        primary={
                          <Typography component="span">
                            {/* eslint-disable-next-line max-len */}
                            <Trans i18nKey="hdSetUpWeb.signInOrSignUp.enterCodeText" components={{ strong: <strong /> }} />
                          </Typography>
                        }
                        sx={{
                          mt: 0,
                          mb: 0,
                        }}
                      />
                    </ListItem>
                  </List>
                  <CustomButton
                  type={'button'}
                  onBtnClick={onLaunchClick}
                  btnText={
                  <Typography component='div' display={'flex'} alignItems={'center'}>
                    <Typography sx={{ fontWeight: 600, mr: theme.spacing(1.25) }}>
                    {t('hdSetUpWeb.signInOrSignUp.launchHdBtnText')}
                    </Typography>
                    <NewTabIcon/>
                  </Typography>}
                  smallerMargin
                  />
                  <Typography>
                  <Trans i18nKey="hdSetUpWeb.signInOrSignUp.whenDoneText" />
                  </Typography>
                </Box>
  );

  const IsDoneCard = () => (
    <Box sx={{ mt: theme.spacing(5) }}>
      <Typography sx={{ textAlign: 'left', fontWeight: 600, mb: theme.spacing(2.5) }}>
      {t('hdSetUpWeb.isDone.heading1')}
      </Typography>
      <CustomButton
      type={'button'}
      onBtnClick={onYesDoneClick}
      btnText={
        <Typography component='span' sx={{ fontWeight: 600, mr: theme.spacing(1.25) }}>
        {t('hdSetUpWeb.isDone.yesDone')}
        </Typography>}
      smallerMargin
      />
      <Box display={'flex'} justifyContent={'center'}>
      <Typography component='div'sx={{
        fontWeight: 600,
        cursor: 'pointer',
        width: 'fit-content',
        color: theme.palette.secondary.dark,
      }}
      onClick={onNotDoneClick}
      >
      {t('hdSetUpWeb.isDone.noDone')}
      </Typography>
      </Box>
    </Box>
  );

  const handleOnPlay = () => {
    trackOnboardEventsWithHeap('clicked-play-hd-onboarding-video', {
      memberId,
      videoUrl: selectedOption === 'yes' ? signInVideoUrl : signUpVideoUrl,
    });
  };

  const handleOnEnd = () => {
    trackOnboardEventsWithHeap('completed-hd-onboarding-video', {
      memberId,
      videoUrl: selectedOption === 'yes' ? signInVideoUrl : signUpVideoUrl,
    });
  };

  return (
    <OnboardingLayout
    customDesktopBanner={
      (showSignInOrSignUp || showIsDone || showEmail) ?
      <VideoPlayer
      videoUrl={selectedOption === 'yes' ? signInVideoUrl : signUpVideoUrl}
        optionalVideoContainerStyle={{
          marginTop: theme.spacing(7.5), // Video Margin Top to fit screen
          marginLeft: 'auto',
          marginRight: 'auto',
          background: theme.palette.common.black,
          maxWidth: theme.spacing(72),
          borderRadius: theme.spacing(2.41),
          padding: theme.spacing(3.125),
          height: '60vh',
        }}
        handleOnPlay={handleOnPlay}
        handleOnEnd={handleOnEnd}
       /> : null
    }
    >
      <Box>
        <Box>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={4000}
            onClose={handleErrorClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {t('error.generalMessage')}
            </Alert>
          </Snackbar>
            <Card
            sx={{
              borderRadius: theme.spacing(1.5),
              backgroundColor: theme.palette.common.white,
            }}
            >
              <CardContent sx={{ padding: `${theme.spacing(3.125)} !important` }}>
                <Box display={'flex'} justifyContent='center' mb={theme.spacing(3.125)}>
                  {[...Array(4).keys()].map((val) => (
                    <Box
                      key={val}
                      sx={{
                        height: '5px',
                        width: '69px',
                        backgroundColor: theme.palette.color19.main,
                        ...(val + 1 <= 3 && {
                          backgroundColor: theme.palette.secondary.dark,
                        }),
                        borderRadius: theme.spacing(12.5),
                        marginRight: theme.spacing(5),
                        ...(val + 1 === 4 && { marginRight: '0px' }),
                      }}
                    />
                  ))}
                </Box>
                <Box sx={{
                  mt: theme.spacing(5),
                  mb: theme.spacing(2.5),
                }}>
                  <Typography variant="h2"
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.color3.main,
                    }}
                    >
                      {t('hdSetUpWeb.heading1')}
                  </Typography>
                </Box>
                <Typography sx={{
                  textAlign: 'left',
                  color: theme.palette.color16.main,
                  mt: theme.spacing(2.5),
                  mb: theme.spacing(2.5),
                }} >
                      {showHDShopQuestion ? t('hdSetUpWeb.heading2') : t('hdSetUpWeb.heading3')}
                 </Typography>
                {showHDShopQuestion ?
                <OnboardHDShopQuestion setShowHDShopQuestion={setShowHDShopQuestion}/> :
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl component="fieldset" sx={{ width: '100%' }}>
                    <Controller
                        name="accountStatus"
                        control={control}
                        defaultValue={selectedOption}
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            aria-label="account status"
                            row
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleRadioChange(e); // Call the handler on change
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            {
                              [
                                { value: 'yes', label: 'Yes', id: 'home-depot-setup-yes-option' },
                                { value: 'no', label: 'No', id: 'home-depot-setup-no-option' },
                                { value: 'unsure', label: "I'm not sure", id: 'home-depot-setup-unsure-option' },
                              ].map((option, indx) => (
                                <FormControlLabel
                                    key={indx}
                                    id={option.id}
                                    value={option.value}
                                    control={
                                      <Radio
                                        sx={{
                                          display: 'none', // Hide default radio button circle
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{
                                          flex: 1, // Makes each button take equal width
                                          border: `1px solid ${theme.palette.grey3.main}`,
                                          borderRadius: theme.spacing(1.5),
                                          padding: theme.spacing(1.875),
                                          textAlign: 'center',
                                          borderColor: (value === option.value ?
                                            theme.palette.secondary.dark :
                                            theme.palette.grey3.main),
                                          '&:hover': {
                                            backgroundColor: theme.palette.secondary.light,
                                            borderColor: theme.palette.secondary.dark,
                                          },
                                          cursor: 'pointer', // Cursor like a button
                                          transition: 'border-color 0.3s',
                                        }}
                                      >
                                        {option.label}
                                      </Typography>
                                    }
                                    {...register('accountStatus')}
                                    sx={{
                                      flex: 1,
                                      mr: indx + 1 !== 3 ? theme.spacing(1.25) : 0,
                                      ml: 0,
                                    }}
                                  />
                              ))
                            }
                          </RadioGroup>
                        )}/>
                  </FormControl>
                  {showSignInOrSignUp && <SignInOrSignUpCard />}
                  {showIsDone && <IsDoneCard/>}
                  {showEmail &&
                  <Box sx={{ mt: theme.spacing(5) }}>
                    <Typography sx={{ textAlign: 'left', fontWeight: 600, mb: theme.spacing(2.5) }}>
                    {t('hdSetUpWeb.enterEmail.heading1')}
                    </Typography>
                    <Box>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue={hdEmail}
                        render={({
                          field: {
                            onChange, onBlur, value,
                          },
                        }) => <TextField
                            value={value} // Set the value directly
                            onChange={(event) => {
                              onChange(event);
                              setHdEmail(event.target.value);
                            }}
                            onBlur={onBlur}
                            placeholder={'Enter your email address'}
                            InputProps={{
                              sx: {
                                borderRadius: theme.spacing(1.5),
                                border: `1px solid  ${theme.palette.grey3.main}`,
                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
                                background: theme.palette.color7.main,
                                fontWeight: '400',
                                fontSize: theme.spacing(2.25),
                                paddingLeft: theme.spacing(2),
                              },
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiOutlinedInput-root.Mui-focused': {
                                '& > fieldset': {
                                  borderColor: formState?.errors?.email && formState.errors.email.type !== 'required' ?
                                    theme.palette.primary.main : theme.palette.secondary.dark,
                                },
                              },
                            }}
                          />}
                        rules={{
                          required: 'email is required',
                          pattern: {
                            value: emailRegex,
                            message: `${t('error.invalidEmail2')}`,
                          },
                        }}
                      />
                      {(formState?.errors?.email && formState.errors.email.type !== 'required') &&
                        <Alert
                          icon={false}
                          sx={{
                            background: theme.palette.common.white,
                            color: theme.palette.error.main,
                            p: 0,
                            '& .MuiAlert-message': {
                              pb: 0,
                            },
                          }}
                          severity='error'>
                          {formState?.errors.email.message}
                        </Alert>}
                    </Box>
                    <CustomButton
                      type={'submit'}
                      btnText={
                        loading ?
                          <CircularProgress color="inherit" size={24} /> :
                          <Typography component='span' sx={{ fontWeight: 600 }}>
                            {t('button.completeHdWebSetup')}
                          </Typography>
                      }
                      disabled={!hdEmail || !formState.isValid || loading}
                    />
                  </Box>}
                </form>}
              </CardContent>
            </Card>
            {!showHDShopQuestion && <PostponeHdSetup/>}
        </Box>
      </Box>
    </OnboardingLayout>
  );
}
