import {
  Alert,
  Box, CircularProgress, FormControl, FormControlLabel,
  Radio, RadioGroup, Snackbar, Typography, useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShoppingStatus } from '../../../common/constants';
import { CustomButton } from './common/CustomHDSetupButton';
import { updateMemberToPartner } from '../../../store/thunks/dashboardThunk';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectMemberSession, updateNavigationFromOnboarding } from '../../../store/slices/onboardingSlice';
import { HOME_ROUTE, REWARDS_ROUTE } from '../../../routes/namedRoutes';
import { useOnboardEventTrackingWithHeap } from '../../../common/useOnboardEventTrackingWithHeap';

interface IFormInputs {
  shoppingStatus: ShoppingStatus | null;
}

interface OnboardHDShopQuestionProps {
  setShowHDShopQuestion: (value: boolean) => void,
}
export function OnboardHDShopQuestion({ setShowHDShopQuestion }:OnboardHDShopQuestionProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { memberId } = useAppSelector(selectMemberSession);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ShoppingStatus | null>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const trackOnboardEventsWithHeap = useOnboardEventTrackingWithHeap();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const {
    register, handleSubmit, control, formState,
  } = useForm<IFormInputs>({ mode: 'onChange' });

  // Handle the radio button change
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value as ShoppingStatus);
  };

  const onSubmit = (data:IFormInputs) => {
    if (data.shoppingStatus) {
      setLoading(true);
      dispatch(updateMemberToPartner({
        memberId,
        partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
        data: { shoppingStatus: data.shoppingStatus },
      }))
        .then(unwrapResult)
        .then(() => {
          setOpenErrorSnackbar(false);

          // fire shopping status event
          trackOnboardEventsWithHeap('submitted-shopping-status-at-home-depot', {
            shoppingStatus: data.shoppingStatus || 'unknown',
          });

          if (data.shoppingStatus === ShoppingStatus.NotShopping) {
            const { fromRewards, userInitiatedHdEnrollment } = location.state || {};
            const redirectPath = fromRewards ? REWARDS_ROUTE : HOME_ROUTE;

            if (!userInitiatedHdEnrollment) {
              dispatch(updateNavigationFromOnboarding(true));
            }
            // navigate to content home page or rewards page
            navigate(redirectPath, {
              state: { fromOnboarding: true },
            });
          } else {
            // show the hd setup instructions
            setShowHDShopQuestion(false);
          }
        })
        .catch((error: unknown) => {
          setOpenErrorSnackbar(true);
          setLoading(false);
        });
    }
  };

  const handleErrorClose = () => {
    setOpenErrorSnackbar(false);
  };

  return (
    <Box>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleErrorClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        >
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <Controller
            name="shoppingStatus"
            control={control}
            defaultValue={null}
            rules={{
              required: 'Please select an option', // Validation message
            }}
            render={({ field: { onChange, value } }) => (
                <RadioGroup
                  aria-label="shopping status"
                  row
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleRadioChange(e); // Call the handler on change
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  {
                    [
                      {
                        value: ShoppingStatus.Shopping,
                        label: 'Yes',
                        id: 'home-depot-shop-option-yes',
                        isDisabled: loading,
                      },
                      {
                        value: ShoppingStatus.NotShopping,
                        label: 'No',
                        id: 'home-depot-shop-option-no',
                        isDisabled: loading,
                      },
                    ].map((option, indx) => (
                      <FormControlLabel
                          key={indx}
                          id={option.id}
                          value={option.value}
                          control={
                            <Radio
                            disabled={option.isDisabled} // Conditionally disable
                              sx={{
                                display: 'none', // Hide default radio button circle
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                flex: 1, // Makes each button take equal width
                                border: `1px solid ${theme.palette.grey3.main}`,
                                borderRadius: theme.spacing(1.5),
                                padding: theme.spacing(1.875),
                                textAlign: 'center',
                                borderColor: (value === option.value ?
                                  theme.palette.secondary.dark :
                                  theme.palette.grey3.main),
                                '&:hover': option.isDisabled ?
                                  {} :
                                  {
                                    backgroundColor: theme.palette.secondary.light,
                                    borderColor: theme.palette.secondary.dark,
                                  },
                                cursor: 'pointer', // Cursor like a button
                                transition: 'border-color 0.3s',
                              }}
                            >
                              {option.label}
                            </Typography>
                          }
                          {...register('shoppingStatus')}
                          sx={{
                            flex: 1,
                            mr: indx + 1 !== 2 ? theme.spacing(1.25) : 0,
                            ml: 0,
                          }}
                      />
                    ))
                  }
                </RadioGroup>
            )}/>
        </FormControl>
        <CustomButton
          type={'submit'}
          btnText={
            loading ?
              <CircularProgress color="inherit" size={24} /> :
              <Typography component='span' sx={{ fontWeight: 600 }}>
                {selectedOption === ShoppingStatus.NotShopping ?
                  t('button.completeHdWebSetup') :
                  t('button.next')
                }
              </Typography>
          }
          disabled={!formState.isValid || loading}
        />
      </form>
    </Box>
  );
}
