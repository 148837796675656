/* eslint-disable no-nested-ternary */
import {
  Alert,
  Box, CircularProgress, Container, FormLabel, Link,
  MenuItem,
  Select,
  Snackbar, TextField, Typography,
} from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from 'i18next';
import { CustomerSupportFormInput } from '@optimus/models';
import { useLogo } from '../config/useLogo';
import { useSolutionOrg } from '../config/useSolutionOrg';
import CallToActionButton from './CallToActionButton';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { submitCustomerSupportForm } from '../store/thunks/authenticationThunk';
import NavHeader from './NavHeader';
import { SolutionOrgList } from './enums';
import theme from '../assets/theme';
import { ValidFieldPattern } from '../components/Onboarding/constants';
import { selectMemberInformation } from '../store/slices/onboardingSlice';

interface IFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  solutionOrg: string;
  description: string;
}

type ErrorProps = {
  errorMessage?: string;
};

interface ICustomerSupportFormProps {
  isDashboardSupportPage?: boolean;
}

const inputStyle = () => ({
  width: '100%',
  mt: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  '& .MuiInputBase-root': {
    borderRadius: theme.spacing(1),
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
} as const);

const labelStyle = () => ({
  color: theme.palette.grey56.main,
} as const);

export const ErrorAlert = ({ errorMessage }: ErrorProps) => (
  <Alert
    icon={false}
    sx={{
      fontSize: theme.spacing(1.75),
      background: theme.palette.common.white,
      color: theme.palette.error.main,
      p: 0,
      '& .MuiAlert-message': {
        padding: `${theme.spacing(0)} ! important`,
      },
    }}
    severity="error"
  >
    {errorMessage || ' This field is required'}
  </Alert>
);

export default function CustomerSupportForm({
  isDashboardSupportPage = false,
}: ICustomerSupportFormProps) {
  const { secondaryOne: Logo } = useLogo();
  const {
    envSolutionOrgTitle, solutionOrgName, productQuestion,
  } = useSolutionOrg();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const solutionOrg = searchParams.get('solutionOrg');
  const memberInformation = useAppSelector(selectMemberInformation);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<IFormInputs>({ mode: 'onChange' });

  const openLoginForm = () => {
    if (solutionOrg) {
      navigate(`/login?solutionOrg=${solutionOrg}`);
    } else {
      navigate('/login');
    }
  };

  const handleClose = () => {
    setOpenErrorSnackbar(false);
  };

  const handleOnChange = () => {
    clearErrors('solutionOrg');
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (!formSubmitted) {
      setIsLoading(true);
      if (data) {
        const request: CustomerSupportFormInput = {
          firstName: isDashboardSupportPage ? memberInformation?.firstName || '' : data.firstName,
          lastName: isDashboardSupportPage ? memberInformation?.lastName || '' : data.lastName,
          email: data.email,
          description: data.description,
          solutionOrg:
            (isDashboardSupportPage || !productQuestion) ? solutionOrgName as string :
              data.solutionOrg || solutionOrg,
          isLoggedIn: isDashboardSupportPage,
        };
        dispatch(submitCustomerSupportForm(request))
          .then(unwrapResult)
          .then(() => {
            setOpenErrorSnackbar(false);
            setIsLoading(false);
            setFormSubmitted(true);
          })
          .catch((err: unknown) => {
            setOpenErrorSnackbar(true);
            setIsLoading(false);
          });
      }
    } else {
      // show support form to create new ticket
      setFormSubmitted(false);
      reset();
    }
  };

  return (
    <>
      {!isDashboardSupportPage && <NavHeader />}
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: isDashboardSupportPage ? 'auto' : theme.spacing(112),
          pr: theme.spacing(3),
          pl: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
          maxWidth: isDashboardSupportPage ?
            theme.breakpoints.values.md :
            theme.breakpoints.values.sm,
        }}
      >
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {t('error.generalMessage')}
          </Alert>
        </Snackbar>
        <>
          {!isDashboardSupportPage && <Box sx={{ textAlign: 'center' }}>
            {Logo && <Logo />}
          </Box>}
          <Box
            sx={{
              background: theme.palette.common.white,
              borderRadius: isDashboardSupportPage ? theme.spacing(1.5) : theme.spacing(2.25),
              [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              },
              paddingLeft: theme.spacing(3.5),
              paddingRight: theme.spacing(3.5),
              paddingTop: theme.spacing(3),
              marginTop: isDashboardSupportPage ? theme.spacing(0) : theme.spacing(3),
              ...(isDashboardSupportPage && {
                marginBottom: theme.spacing(5),
                border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
              }),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(1),
                margin: isDashboardSupportPage ? theme.spacing(0) : '0 auto',
              }}
            >
              {!isDashboardSupportPage &&
                <Typography variant="h4" sx={{
                  mb: theme.spacing(4),
                }}>
                  Contact Support
                </Typography>
              }
              <form onSubmit={handleSubmit(onSubmit)}
                style={{ width: isDashboardSupportPage ? '100%' : 'auto' }}
              >
                {!formSubmitted ?
                  <Box>
                    {!isDashboardSupportPage &&
                      <Box
                        sx={{
                          [theme.breakpoints.up('sm')]: {
                            display: 'flex',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            mb: theme.spacing(3),
                            [theme.breakpoints.up('sm')]: {
                              width: '50%',
                              mr: theme.spacing(2),
                            },
                          }}
                        >
                          <FormLabel sx={labelStyle}>First Name*</FormLabel>
                          <TextField
                            placeholder='First Name'
                            {...register('firstName', { required: true })}
                            fullWidth
                            sx={inputStyle}
                          />
                          {(errors.firstName) && <ErrorAlert />}
                        </Box>
                        <Box
                          sx={{
                            mb: theme.spacing(3),
                            [theme.breakpoints.up('sm')]: {
                              width: '50%',
                            },
                          }}
                        >
                          <FormLabel sx={labelStyle}>Last Name*</FormLabel>
                          <TextField
                            placeholder='Last Name'
                            {...register('lastName', { required: true })}
                            margin="normal"
                            fullWidth
                            sx={inputStyle}
                          />
                          {(errors.lastName) && <ErrorAlert />}
                        </Box>
                      </Box>
                    }
                    <Box sx={{ mb: theme.spacing(3) }}>
                      <FormLabel sx={labelStyle}>Email Address*</FormLabel>
                      <TextField
                        defaultValue={isDashboardSupportPage ? memberInformation.email : ''}
                        placeholder='Email Address'
                        {...register('email', {
                          required: true,
                          pattern: {
                            value: ValidFieldPattern.validEmailPattern,
                            message: `${t('error.invalidEmail2')}`,
                          },
                        })}
                        margin="normal"
                        fullWidth
                        sx={inputStyle}
                      />
                      {(errors.email) && <ErrorAlert
                        errorMessage={errors.email?.message} />}
                    </Box>
                    {!isDashboardSupportPage && productQuestion as boolean &&
                      <Box sx={{ mb: theme.spacing(3) }}>
                        <FormLabel sx={labelStyle}>What product do you use?*</FormLabel>
                        <Select
                          displayEmpty
                          {...register('solutionOrg', { required: true })}
                          defaultValue=''
                          onChange={(e) => { handleOnChange(); }}
                          sx={inputStyle}
                        >
                          <MenuItem disabled value=''>
                            Please select an option
                          </MenuItem>
                          {Object.values(SolutionOrgList).map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {(errors.solutionOrg) && <ErrorAlert />}
                      </Box>
                    }
                    <Box>
                      <FormLabel sx={labelStyle}>Description*</FormLabel>
                      <TextField
                        placeholder='Please describe the issue you are facing'
                        {...register('description', { required: true })}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        sx={inputStyle}
                      />
                      {(errors.description) && <ErrorAlert />}
                    </Box>
                  </Box> : <Alert icon={<CheckCircleIcon />}
                    sx={{ bgcolor: theme.palette.common.white }}
                  >
                    {t('success.supportTicketSubmitted')}
                  </Alert>
                }
                <CallToActionButton
                  text={isLoading ?
                    <CircularProgress color="inherit" size={24} /> :
                    (formSubmitted ? `${t('button.createNewTicket')}` :
                      `${t('button.submitSupportTicket')}`)}
                  type={'submit'}
                  sx={{
                    background: theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    '&:disabled': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    position: 'static',
                    width: '100%',
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(4),
                    paddingTop: theme.spacing(0.5),
                    paddingBottom: theme.spacing(0.5),
                    borderRadius: theme.spacing(1),
                    fontWeight: '600',
                    fontSize: theme.spacing(2.35),
                    [theme.breakpoints.up('sm')]: {
                      width: '100%',
                      marginLeft: '0px',
                    },
                  }}
                  disable={isLoading}
                />
              </form>
            </Box>
          </Box>
          {!isDashboardSupportPage && <Box
            sx={{
              mt: theme.spacing(3),
              textAlign: 'center',
            }}
          >
            <Typography variant='body7'>{t('supportForm.or')} &nbsp;
              <Link onClick={() => openLoginForm()}
                sx={{
                  cursor: 'pointer',
                }}
                variant='h6' color={theme.palette.secondary.main}>
                {`${t('supportForm.backToLogin')} ${envSolutionOrgTitle}`}</Link>
            </Typography>
          </Box>}
        </>
      </Container>
    </>
  );
}
