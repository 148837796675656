import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CallToActionButton from '../../../common/CallToActionButton';
import { ReactComponent as Step1Icon } from '../../../assets/images/step1_icon.svg';
import { ReactComponent as Step2Icon } from '../../../assets/images/step2_icon.svg';
import { ReactComponent as Step3Icon } from '../../../assets/images/step3_icon.svg';
import { ReactComponent as HomedepotLogo } from '../../../assets/images/homedepot_logo.svg';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { PostponeHdSetup } from './common/PostponeHdStep';
import { OnboardHDShopQuestion } from './OnboardHDShopQuestion';
import OnboardingLayout from './common/OnboardingLayout';
import { useSolutionOrg } from '../../../config/useSolutionOrg';

export function OnboardHDHome() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isIframe } = useSolutionOrg();
  const trackOnboardEvent = useOnboardEventTracking();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showHDShopQuestion, setShowHDShopQuestion] = useState(!location.state?.fromRewards);

  useEffect(() => {
    setShowHDShopQuestion(!location.state?.fromRewards);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    calledFromHomeDepotSetUpIframe: true,
  });

  const navigateToHomeDepot = () => {
    trackOnboardEvent('home_depot_complete_setup_clicked');
    window.parent.postMessage({
      payload: { hideOptimusApp: true },
      type: 'OPTIMUS_HIDE_REQUEST',
    }, '*');
  };

  return (
    <OnboardingLayout reduceMobileTopPadding={isIframe}>
      <Box>
        <Box>
          {/* <Typography sx={{
            // mt: theme.spacing(2),
            textAlign: 'center',
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
          }}>{t('home.headingEdge')}</Typography> */}
          <Box
            sx={{
              width: '100%',
              mt: theme.spacing(0.25),
            }}
          >
            <Stack
              spacing={0}
              sx={() => ({
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                pb: showHDShopQuestion ? theme.spacing(2) : theme.spacing(0),
                marginTop: showHDShopQuestion ? theme.spacing(2) : theme.spacing(0),
              })}
            >
              <Box display={'flex'} justifyContent='center'
                mt={theme.spacing(0.5)}
                >
                {[...Array(4).keys()].map((val) => (
                  <Box
                    key={val}
                    sx={{
                      height: theme.spacing(0.625),
                      width: theme.spacing(8.625),
                      backgroundColor: theme.palette.color19.main,
                      ...(val + 1 <= 3 && {
                        backgroundColor: theme.palette.secondary.dark,
                      }),
                      borderRadius: theme.spacing(12.5),
                      marginRight: theme.spacing(5),
                      ...(val + 1 === 4 && { marginRight: '0px' }),
                    }}
                  />
                ))}
              </Box>
              {showHDShopQuestion && <Box sx={{
                mt: theme.spacing(2.5),
              }}>
                  <Typography variant="h2"
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.color3.main,
                    }}
                    >
                      {t('hdSetUpWeb.heading1')}
                  </Typography>
                </Box>}
              {showHDShopQuestion && <Typography sx={{
                textAlign: 'left',
                color: theme.palette.color16.main,
                mt: theme.spacing(2.5),
                mb: theme.spacing(2.5),
              }} >
                  {t('hdSetUpWeb.heading2')}
              </Typography>}
              {showHDShopQuestion ?
                <OnboardHDShopQuestion setShowHDShopQuestion={setShowHDShopQuestion}/> :
              <>
                <Box sx={{ display: 'flex', mt: theme.spacing(2.5) }}>
                  <Box>
                    <HomedepotLogo />
                  </Box>
                  <Box sx={{ marginLeft: '15px', textAlign: 'left' }}>
                    <Typography variant='body4'>{t('home.descriptionEdge1')}</Typography>
                    <Typography variant='body4'>{t('home.descriptionEdge2')}</Typography>
                    <Typography sx={{ marginTop: '0px' }}>{t('home.descriptionEdge3')}</Typography>
                  </Box>
                </Box>
                <List sx={{
                  width: '100%', maxWidth: 360, bgcolor: 'background.paper', pb: 0,
                }}>
                  <ListItem sx={{
                    border: '1px solid #DADADA',
                    borderRadius: theme.spacing(1.625),
                  }}>
                    <ListItemAvatar>
                      <Step1Icon />
                    </ListItemAvatar>
                    <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step1.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step1.description1')}</span> {t('home.step1.description2')} <span style={{ fontWeight: '600' }}>{t('home.step1.description3')}</span> {t('home.step1.description4')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step1.description5')}</div></div>} />
                  </ListItem>
                  <ListItem sx={{
                    border: '1px solid #DADADA',
                    borderRadius: theme.spacing(1.625),
                    marginTop: '8px',
                  }}>
                    <ListItemAvatar>
                      <Step2Icon />
                    </ListItemAvatar>
                    <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step2.heading')}</div><div style={{ fontWeight: '600' }}>{t('home.step2.description1')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step2.description2')}</div></div>} />
                  </ListItem>
                  <ListItem sx={{
                    border: '1px solid #DADADA',
                    borderRadius: theme.spacing(1.625),
                    marginTop: '8px',
                  }}>
                    <ListItemAvatar>
                      <Step3Icon />
                    </ListItemAvatar>
                    <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step3.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step3.descriptionEdge1')}</span> {t('home.step3.descriptionEdge2')} <span style={{ fontWeight: '600' }}>{t('home.step3.descriptionEdge2b')}</span></div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step3.descriptionEdge3')}</div></div>} />
                  </ListItem>
                </List>
                <Box>
                  <CallToActionButton
                    text={t('button.completeSetup') as string}
                    onBtnClick={navigateToHomeDepot}
                    sx={() => ({
                      position: 'static',
                      width: '100%',
                      // maxWidth: theme.spacing(45), // 360px
                      marginTop: theme.spacing(2.5),
                      marginBottom: theme.spacing(1),
                      background: theme.palette.secondary.dark,
                      borderRadius: theme.spacing(1.5),
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                      [theme.breakpoints.up('sm')]: {
                        width: '100%',
                        marginLeft: '0px',
                      },
                    })}
                  />
                </Box>
              </>
              }
            </Stack>
            {!showHDShopQuestion && <PostponeHdSetup/>}
          </Box>
        </Box>
      </Box>
    </OnboardingLayout>
  );
}
