import { Navigate, useOutlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import AuthenticatedRoute from '../AuthenticatedRoute';
import { DashboardLayout } from './DashboardLayout';
import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';
import { WelcomeModal } from '../../common/WelcomeModal';
import { getMemberConfig } from '../../store/thunks/dashboardThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getMemberInformation } from '../../store/thunks/onboardingThunk';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { useTracking } from '../../common/useTracking';

export function DashboardHome() {
  const outlet = useOutlet();
  const dispatch = useAppDispatch();
  const trackEvent = useTracking();
  const memberSession = useAppSelector(selectMemberSession);
  const [openModal, setOpenModal] = useState(false);

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
  });

  useEffect(() => {
    dispatch(getMemberInformation(
      {
        id: memberSession.memberId,
        trackEvent,
      },
    ));

    dispatch(getMemberConfig()).then(unwrapResult)
      .then((memberConfig) => {
        setOpenModal(memberConfig?.showWelcomeModal);
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <AuthenticatedRoute>
        <>
          <WelcomeModal openModal={openModal} setOpenModal={setOpenModal}/>
          <DashboardLayout>
            {outlet || <Navigate to="/home" />}
        </DashboardLayout>
        </>
      </AuthenticatedRoute>
  );
}
