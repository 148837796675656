import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ReactComponent as BehrLogo } from '../../assets/images/behr_logo.svg';
import { ReactComponent as KilzLogo } from '../../assets/images/kilz_logo.svg';
import { ReactComponent as HDLogo } from '../../assets/images/hd_logo.svg';
import { ReactComponent as HDSupplyLogo } from '../../assets/images/hdSupply_logo.svg';
import {
  selectGetHomeDepotMemberRelationState,
  selectHomeDepotMemberRelation,
  selectGetHDSupplyMemberRelationState,
  selectHDSupplyMemberRelation,
} from '../../store/slices/dashboardSlice';
import { HDSupplyEnrollmentStatus, HDVerificationStatus } from '../../common/enums';
import { PartnerCardSkeleton } from '../../common/PartnerEnrollmentCardSkeleton';
import { PartnerCard } from '../../common/PartnerEnrollmentCardV2';
import { getHDSupplyMemberRelation, getHomeDepotMemberRelation } from '../../store/thunks/dashboardThunk';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { OuterContainer } from './DashboardOuterContainerPagesLayout';

export function Partners() {
  const dispatch = useAppDispatch();
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const hdMemberRelationState = useAppSelector(selectGetHomeDepotMemberRelationState);
  const HDSupplyMemberRelation = useAppSelector(selectHDSupplyMemberRelation);
  const HDSupplyMemberRelationState = useAppSelector(selectGetHDSupplyMemberRelationState);
  const [hdVerificationStatus, setHDVerificationStatus] = useState(HDVerificationStatus.Pending);
  const [
    hdSupplyVerificationStatus,
    setHDSupplyVerificationStatus,
  ] = useState(HDSupplyEnrollmentStatus.Pending);
  const { t } = useTranslation();
  const { behrFlag, hdSupplyFlag } = useFeatureFlags();
  const theme = useTheme();

  useEffect(() => {
    if (!hdMemberRelation) {
      dispatch(getHomeDepotMemberRelation());
    }

    if (!HDSupplyMemberRelation) {
      dispatch(getHDSupplyMemberRelation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hdMemberRelation?.partnerMemberKey || hdMemberRelation?.partnerMemberEmail) {
      setHDVerificationStatus(HDVerificationStatus.Completed);
    } else {
      setHDVerificationStatus(HDVerificationStatus.Pending);
    }

    if (HDSupplyMemberRelation?.partnerMemberKey || HDSupplyMemberRelation?.partnerMemberEmail) {
      setHDSupplyVerificationStatus(HDSupplyEnrollmentStatus.Done);
    } else {
      setHDSupplyVerificationStatus(HDSupplyEnrollmentStatus.Pending);
    }
  }, [hdMemberRelation, HDSupplyMemberRelation]);

  const partnersData = [
    {
      name: t('homeDepot.title'),
      logo: HDLogo,
      description: t('homeDepot.partnerDescription'),
      perks: [`${t('partners.perk1')}`],
      links: [
        { text: t('homeDepot.link1'), url: 'https://www.homedepot.com/l/store-locator' },
        { text: t('homeDepot.link2'), url: 'https://www.homedepot.com/c/mobile-app' },
        { text: t('homeDepot.link3'), url: 'https://www.homedepot.com/SpecialBuy/ProSpecialBuyOfTheWeek' },
      ],
      flag: true,
      enrollment: hdVerificationStatus,
    },
    {
      name: t('hdSupply.title'),
      logo: HDSupplyLogo,
      description: t('hdSupply.partnerDescription'),
      perks: [`${t('partners.perk1')}`, `${t('partners.perk4')}`, `${t('partners.perk5')}`],
      links: [
        { text: t('hdSupply.link1'), url: 'https://www.ebarnett.com/info/inventory-solutions' },
        { text: t('hdSupply.link2'), url: 'https://www.ebarnett.com/info/online-shopping-advantages?ab=rc_ss_resi_onlineshoppingtools' },
        { text: t('hdSupply.link3'), url: 'https://www.ebarnett.com/info/locations' },
      ],
      flag: hdSupplyFlag,
      enrollment: hdSupplyVerificationStatus,
    },
    {
      name: t('behr.title'),
      logo: BehrLogo,
      description: t('behr.partnerDescription'),
      perks: [`${t('behr.perk1')}`, `${t('behr.perk2')}`, `${t('behr.perk3')}`],
      officialLink: {
        text: 'behrpro.com',
        url: 'https://www.behr.com/pro',
        moreInfo: t('behr.moreInfo'),
      },
      links: [
        { text: t('behr.link1'), url: 'https://www.homedepot.com/c/pro-paint-services-and-rewards' },
        { text: t('behr.link2'), url: 'https://www.behr.com/pro/pro-hub-stores?location=sdnd' },
        { text: t('behr.link3'), url: 'https://www.behr.com/pro/professional-detail/painting-contractors' },
      ],
      flag: behrFlag,
      enrollment: hdVerificationStatus,
    },
    {
      name: t('kilz.title'),
      logo: KilzLogo,
      description: t('kilz.partnerDescription'),
      perks: [`${t('kilz.perk1')}`, `${t('kilz.perk2')}`],
      officialLink: {
        text: 'kilz.com',
        url: 'https://www.kilz.com/',
        moreInfo: t('kilz.moreInfo'),
      },
      links: [
        { text: t('kilz.link1'), url: 'https://www.kilz.com/blog/inspiration/' },
        { text: t('kilz.link2'), url: 'https://www.kilz.com/calculator' },
      ],
      flag: behrFlag,
      enrollment: hdVerificationStatus,
    },
  ];

  return (
    <OuterContainer
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0),
      }}
    >
      <>
        <Box
          sx={{
            alignSelf: 'flex-start',
            width: '100%',
            marginBottom: theme.spacing(5),
          }}
        >
          <Typography variant="h12">
            {t('navigation.partners')}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          {
            (hdMemberRelationState === 'pending' || HDSupplyMemberRelationState === 'pending') ?
              <Box>
                <PartnerCardSkeleton numberOfSkeletons={3} />
              </Box> :
              <Box>
                {partnersData
                  .filter((partner) => partner.flag)
                  .map((partner, index) => (
                    <PartnerCard
                      key={index}
                      PartnerLogo={partner.logo}
                      partnerInfo={partner}
                      enrolled={partner.enrollment}
                    />
                  ))
                }
              </Box>
          }
        </Box>
      </>
    </OuterContainer>
  );
}
