import React, { useState } from 'react';
import {
  Box, Grid, Typography, useTheme,
} from '@mui/material';
import { QuestionOption } from './models/Question';

type ImageOptionComponentProps = {
  options?: QuestionOption[];
  onChange?: (...event: unknown[]) => void;
  optionalBoxStyles?: {
    [k: string]: unknown;
  }
  ['data-testid']?: string;
};

export default function ImageOptionComponent({
  options,
  onChange,
  optionalBoxStyles,
  'data-testid': dataTestId,
}: ImageOptionComponentProps) {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState(options?.filter((item) => item.active) || []);

  const handleClick = (index: number) => {
    const newItems = [...defaultValues];
    // toggle option selection
    newItems[index] = { ...newItems[index], isSelected: !newItems[index].isSelected };
    setDefaultValues(newItems);
    if (onChange) {
      // pass data to the parent component
      onChange(newItems);
    }
  };
  return (
    <Box
      sx={{
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(4),
          marginLeft: theme.spacing(-6),
          width: '125% !important',
        },
        ...(optionalBoxStyles && optionalBoxStyles),
      }}
      data-testid={dataTestId}
    >
      <Grid container justifyContent="center" spacing={{
        xs: 1, sm: 1, md: 1, lg: 2,
      }} columns={{
        xs: 12, sm: 12, md: 12, lg: 12,
      }}>
        {defaultValues?.map((item, index) => (
          <Grid item xs={5} sm={4} md={4} lg={4} key={item.value}
            onClick={() => handleClick(index)}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Box
                border={item.isSelected ? `6px solid ${theme.palette.secondary.main}` : `6px solid ${theme.palette.grey[100]}`}
                sx={{
                  borderRadius: '57px',
                  height: '109px',
                  width: '111px',
                }}
              >
                <img src={item.imageUrl} alt={item.display} />
              </Box>
              <Typography component={'div'} color={theme.palette.color2.main}>{item.display}</Typography>
            </Box>
          </Grid >
        ))}
      </Grid >
    </Box >
  );
}
